import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Config from "../Config";
import { userLogout } from "../functions/api/logout";
import { getUser } from "../functions/api/user";
import { AiOutlineShoppingCart } from "react-icons/ai";
import { FaBars, FaTimes } from "react-icons/fa";
import Cart from "../functions/cart";

export var exportUpdateCart;
export var exportUpdateUser;

export default function Navbar() {
  const [Data, setData] = useState([]);
  const [Logged, setLogged] = useState(false);
  const [CartItems, setCartItems] = useState(0);
  const [Menu, setMenu] = useState(false);
  const [Mobile, setMobile] = useState(false);
  const [ProfileState, setProfileState] = useState(false);

  let user = window.localStorage.getItem("gas-web-user");

  function handleOnMenuStateChange(state) {
    if (state !== "" || state !== undefined) {
      setMenu(state);
    } else {
      setMenu(!Menu);
    }
  }

  useEffect(() => {
    let isMounted = true;

    if (isMounted) {
      fetchUserData();
    }

    return () => {
      isMounted = false;
    };
  }, []);

  exportUpdateUser = () => {
    fetchUserData();
  };

  function fetchUserData() {
    getUser((data) => {
      setData(data);
      setLogged(true);
    });
  }

  useEffect(() => {
    let isMounted = true;

    if (isMounted) {
      handleOnResize();
      window.addEventListener("resize", handleOnResize);
      window.addEventListener("click", handleOnClick);
    }

    return () => {
      isMounted = false;
    };
  }, []);

  function handleOnClick(e) {
    if (e.target) {
      let valid = [
        "navbar-profile-icon-wrap",
        "navbar-profile-image",
        "navbar-profile-item",
      ];

      if (e.target.classList) {
        for (let item of valid) {
          if (e.target.classList.contains(item)) return;
        }
      }

      setProfileState(false);
    }
  }

  function handleOnResize() {
    if (window.innerWidth <= 1180) {
      setMobile(true);
    } else {
      setMobile(false);
    }
  }

  function handleOnProfileClcik(state) {
    setProfileState(state);
  }

  function handleOnLogout() {
    userLogout();
    handleOnProfileClcik(false);
  }

  useEffect(() => {
    let isMounted = true;

    if (isMounted) {
      updateCart();
    }

    return () => {
      isMounted = false;
    };
  }, [user]);

  exportUpdateCart = async () => {
    updateCart();
  };

  function updateCart() {
    Cart.get((data) => {
      setCartItems(data.length);
    });
  }

  function NavbarProfile({ mobileState, mobile }) {
    return (
      <>
        {mobileState ? (
          <div
            className="navbar-profile-icon-wrap"
            data-active={mobileState}
            data-mobile={mobile}
          >
            <img
              onClick={() => handleOnProfileClcik(!ProfileState)}
              src={
                Data.data
                  ? Data.data.image
                    ? Config.server + Data.data.image
                    : "/images/profile-empty.png"
                  : "/images/profile-empty.png"
              }
              alt="Profile"
              className="navbar-profile-image"
            />

            <div
              className="navbar-profile-content-wrap"
              data-active={ProfileState}
            >
              <Link
                onClick={() => handleOnProfileClcik(false)}
                to="/cart"
                className="navbar-profile-item"
              >
                <div className="navbar-cart-icon-wrap">
                  <AiOutlineShoppingCart className="navbar-cart-icon" />
                  <div className="navbar-cart-icon-number">{CartItems}</div>
                </div>
                Cart
              </Link>
              {Logged ? (
                <span
                  onClick={() => handleOnLogout()}
                  to="/logout"
                  className="navbar-profile-item navbar-profile-item-red"
                >
                  Logout
                </span>
              ) : (
                <Link
                  onClick={() => handleOnProfileClcik(false)}
                  to="/login"
                  className="navbar-profile-item navbar-profile-item-red"
                >
                  Login
                </Link>
              )}
            </div>
          </div>
        ) : (
          <>
            {Logged ? (
              <span
                onClick={() => handleOnLogout()}
                to="/logout"
                className="navbar-link-item"
              >
                Logout
              </span>
            ) : (
              <Link
                onClick={() => handleOnProfileClcik(false)}
                to="/login"
                className="navbar-link-item"
              >
                Login
              </Link>
            )}
            <Link
              onClick={() => handleOnProfileClcik(false)}
              to="/cart"
              className="navbar-profile-item"
            >
              <div className="navbar-cart-icon-wrap">
                <AiOutlineShoppingCart className="navbar-cart-icon" />
                <div className="navbar-cart-icon-number">{CartItems}</div>
              </div>
            </Link>
          </>
        )}
      </>
    );
  }

  return (
    <div id="navbar">
      <div className="navbar-section navbar-top-wrap">
        <Link to="/">
          <img src="/images/logo.png" alt="Logo" className="navbar-logo" />
        </Link>
      </div>
      <div className="navbar-section navbar-menu-wrap">
        <NavbarProfile mobileState={Mobile} mobile={true} />
        {Menu ? (
          <FaTimes
            className="navbar-menu-icon"
            onClick={() => handleOnMenuStateChange(false)}
          />
        ) : (
          <FaBars
            className="navbar-menu-icon"
            onClick={() => handleOnMenuStateChange(true)}
          />
        )}
      </div>
      <div className="navbar-section navbar-links-wrap" data-active={Menu}>
        <Link
          onClick={() => handleOnMenuStateChange(false)}
          to="/features"
          className="navbar-link-item"
        >
          Features
        </Link>
        <Link
          onClick={() => handleOnMenuStateChange(false)}
          to="/shop"
          className="navbar-link-item"
        >
          Shop
        </Link>
        <Link
          onClick={() => handleOnMenuStateChange(false)}
          to="/pricing"
          className="navbar-link-item"
        >
          Pricing
        </Link>
        <Link
          onClick={() => handleOnMenuStateChange(false)}
          to="/resources"
          className="navbar-link-item"
        >
          Resources
        </Link>
        <Link
          onClick={() => handleOnMenuStateChange(false)}
          to="/contactus"
          className="navbar-link-item"
        >
          Contact Us
        </Link>
        <a
          onClick={() => handleOnMenuStateChange(false)}
          href="https://cloud.casi.io"
          // href="http://localhost:3001"
          target="_blank"
          className="navbar-link-item-button"
        >
          <button className="button">Dashboard</button>
        </a>

        <NavbarProfile mobileState={Mobile} mobile={false} />
      </div>
    </div>
  );
}
