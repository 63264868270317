import { server } from '../../Config'
import { notify } from '../notify'
import axios from 'axios';

export async function submitContact(data, callback) {
    axios({
        method: 'POST',
        url: server+'api/createContact',
        withCredentials: true,
        data: data
    })
    .then(res => {
        notify(res.data.status, res.data.message)
        callback(res.data.status)
    })
    .catch(err => {
        callback('failed')
        console.log(err)
        if(err.response){
            if(err.response.data.message){
                notify('error', err.response.data.message)
            }
        }else{
            notify('error', 'Error')
        }
    })
}