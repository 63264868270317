import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import Stack from '@mui/material/Stack';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Check from '@mui/icons-material/Check';
import SettingsIcon from '@mui/icons-material/Settings';
import DashboardIcon from '@mui/icons-material/Dashboard';
import VideoLabelIcon from '@mui/icons-material/VideoLabel';
import StepConnector, { stepConnectorClasses } from '@mui/material/StepConnector';
import { Button } from '@mui/material';

const QontoConnector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 10,
    left: 'calc(-50% + 16px)',
    right: 'calc(50% + 16px)',
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: '#784af4',
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: '#784af4',
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    borderColor: theme.palette.mode === 'dark' ? theme.palette.grey[800] : '#eaeaf0',
    borderTopWidth: 3,
    borderRadius: 1,
  },
}));

const QontoStepIconRoot = styled('div')(({ theme, ownerState }) => ({
  color: theme.palette.mode === 'dark' ? theme.palette.grey[700] : '#eaeaf0',
  display: 'flex',
  height: 22,
  alignItems: 'center',
  ...(ownerState.active && {
    color: '#784af4',
  }),
  '& .QontoStepIcon-completedIcon': {
    color: '#784af4',
    zIndex: 1,
    fontSize: 18,
  },
  '& .QontoStepIcon-circle': {
    width: 8,
    height: 8,
    borderRadius: '50%',
    backgroundColor: 'currentColor',
  },
}));

function QontoStepIcon(props) {
  const { active, completed, className } = props;

  return (
    <QontoStepIconRoot ownerState={{ active }} className={className}>
      {completed ? (
        <Check className="QontoStepIcon-completedIcon" />
      ) : (
        <div className="QontoStepIcon-circle" />
      )}
    </QontoStepIconRoot>
  );
}

QontoStepIcon.propTypes = {
  /**
   * Whether this step is active.
   * @default false
   */
  active: PropTypes.bool,
  className: PropTypes.string,
  /**
   * Mark the step as completed. Is passed to child components.
   * @default false
   */
  completed: PropTypes.bool,
};

const ColorlibConnector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 22,
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundImage:
        'linear-gradient(to bottom right, #13d9ff, #fa5cd6)',
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundImage:
        'linear-gradient(to bottom right, #13d9ff, #fa5cd6)',
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    height: 3,
    border: 0,
    backgroundColor:
      theme.palette.mode === 'dark' ? theme.palette.grey[800] : '#eaeaf0',
    borderRadius: 1,
  },
}));

const ColorlibStepIconRoot = styled('div')(({ theme, ownerState }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? theme.palette.grey[700] : '#ccc',
  zIndex: 1,
  color: '#fff',
  width: 50,
  height: 50,
  display: 'flex',
  borderRadius: '50%',
  justifyContent: 'center',
  alignItems: 'center',
  ...(ownerState.active && {
    backgroundImage:
      'linear-gradient(to bottom right, #13d9ff, #fa5cd6)',
    boxShadow: '0 4px 10px 0 rgba(0,0,0,.25)',
  }),
  ...(ownerState.completed && {
    backgroundImage:
      'linear-gradient(to bottom right, #13d9ff, #fa5cd6)',
  }),
}));

function ColorlibStepIcon(props) {
  const { active, completed, className } = props;

  const icons = {
    1: <SettingsIcon style={{fontSize: '2.3rem'}} />,
    2: <DashboardIcon style={{fontSize: '2.3rem'}} />,
    3: <VideoLabelIcon style={{fontSize: '2.3rem'}} />,
  };

  return (
    <ColorlibStepIconRoot ownerState={{ completed, active }} className={className}>
      {icons[String(props.icon)]}
    </ColorlibStepIconRoot>
  );
}

ColorlibStepIcon.propTypes = {
  /**
   * Whether this step is active.
   * @default false
   */
  active: PropTypes.bool,
  className: PropTypes.string,
  /**
   * Mark the step as completed. Is passed to child components.
   * @default false
   */
  completed: PropTypes.bool,
  /**
   * The label displayed in the step icon.
   */
  icon: PropTypes.node,
};

const steps = ['Select campaign settings', 'Create an ad group', 'Create an ad'];

export default function StepperSection({active}) {
  const [Index, setIndex] = useState(0);

  function handleOnBack() {
    setIndex(Index - 1)
    scrollToTargetAdjusted()
  }
  
  function handleOnNext() {
    setIndex(Index + 1)
    scrollToTargetAdjusted()
  }

  function scrollToTargetAdjusted(){
    if(window.innerWidth < 770) {
      setTimeout(() => {
        const element = document.getElementById('stepper-section-content');
        const offset = 200;
        const bodyRect = document.body.getBoundingClientRect().top;
        const elementRect = element.getBoundingClientRect().top;
        const elementPosition = elementRect - bodyRect;
        const offsetPosition = elementPosition - offset;
  
        window.scrollTo({
          top: offsetPosition,
          behavior: 'smooth'
        });
      }, 100);
    } 
}


  return (
    <div id="stepper-section" data-active={active} >
      <Stack sx={{ width: '100%' }} spacing={4}>
        <Stepper alternativeLabel activeStep={Index} connector={<ColorlibConnector />}>
          {steps.map((label) => (
            <Step key={label}>
              <StepLabel StepIconComponent={ColorlibStepIcon}>{label}</StepLabel>
            </Step>
          ))}
        </Stepper>
      </Stack>

      <div className="stepper-section-content" id="stepper-section-content">
        <div className="stepper-step" data-active={Index === 0}>
          <p className="stepper-step-title">Connect your thing to our MQTT cloud broker</p>
          <p className="stepper-step-desc">
            Bring your own device or use our devices to our cloud infrastructure by connecting to our MQTT
            broker. The CASI MQTT broker can be used for testing, prototyping, and deploying millions of
            things.. No need to set up the infrastructure yourself; the CASI mqtt brokers come with SSL
            encryption out of the box. Join the rapidly growing movement of millions of developers,
            engineers, businesses and other organizations who are all coming together to create a brighter
            future without software insanity. Connect your equipment to our mqtt broker and subscribe to
            any topic in order to have your devices give you updates whenever they're invoked by other
            systems on the network. Customers can use CASI's MQTT Broker for companies looking for
            route optimization, fleet management or any application where strategic accountability is
            essential for success.
          </p>
        </div>
        <div className="stepper-step" data-active={Index === 1}>
          <p className="stepper-step-title">Create Beautiful Dashboards, KPIs, Alarms, and Reports on CASI</p>
          <p className="stepper-step-desc">
            Once your thing is connected to our MQTT broker you can create real-time status data and
            managers track production KPIs and trends in the Dashboard. The CASI IoT platform
            is a turnkey solution for any project that requires real-time data tracking and forecasts.
            Our easy setup process offers a complete package of data processing, storage,
            visualization, and management tools all in one handy dashboard."
          </p>
          <p className="stepper-step-desc">
            Bring your data to life with a full-featured dashboard builder that automatically
            transforms raw content, data sets, and visualizations into beautiful web pages you can
            share. Share stunning dashboards from any link or publish them to almost anywhere:
            WordPress site, Facebook, Twitter, Google Docs spreadsheet... without requiring custom
            coding. Your company has great data. We just need to teach you how to see it. CASI
            helps you create eye-catching, informative dashboards that will help you visualize your
            findings and convert these findings into valuable insights for your business.
          </p>
        </div>
        <div className="stepper-step" data-active={Index === 2}>
          <p className="stepper-step-title">Finally get your equipment “talking”!</p>
          <p className="stepper-step-desc">
            Set up advanced analytics, reporting, and alerting on critical equipment data on the
            CASI iot cloud platform. Your equipment is vitals-it’s essential to your operations.
            Ensure it’s running at peak efficiency and cutting-edge monitoring with CASI, the
            newest IoT solution available today in all industries. Realize cost savings in production
            output today with CASI iot!
          </p>
        </div>
      </div>

      <div className="stepper-section-button-wrap">
        <div className='stepper-section-buttons'>
            <Button variant="outlined" className='stepper-button' disabled={Index === 0}  onClick={handleOnBack} >Back</Button>
            <Button variant="outlined" className='stepper-button' disabled={Index === 2}  onClick={handleOnNext} >Next</Button>
        </div>
      </div>
    </div>
  );
}