import React, { useState } from "react";
import { Link } from "react-router-dom";
import { RatingStar } from "rating-star";
import Config from "../Config";
import { submitContact } from "../functions/api/contact";
import { notify } from "../functions/notify";
import uuid from "react-uuid";
import Reviews from "./HomePageData/reviews.json";
import Features from "./HomePageData/features.json";
import Typed from "react-typed";
import StepperSection from "../components/StepperSection";
import { IoVolumeMuteOutline } from "react-icons/io5";
import ReactPlayer from "react-player/youtube";
import { Carousel } from "react-responsive-carousel";

export default function Home() {
  const [Muted, setMuted] = useState(true);

  const TypeWriterSentences = [
    "White label IoT solution In the push of a button",
    "Bring your own IoT solution to market in minutes",
    "Easily create custom IoT apps with no programming skills required",
    "Bring your own IoT solution to market in minutes",
    "Video",
    "Equipment Dashboard",
  ];

  const TypeWriter2Sentences = [
    "1. Connect your thing to our MQTT cloud broker",
    "2. Create Beautiful Dashboards, KPIs, Alarms, and Reports on CASI",
    "3. Finally get your equipment “talking”!",
  ];

  const Ratings = Reviews["ratings"];

  const Reviews1 = Reviews["reviews_1"];
  const Reviews2 = Reviews["reviews_2"];
  const FeaturesSections = Features["sections"];

  function handleOnContactFormSubmit(e) {
    let inputFields = document.getElementsByClassName("h-c-f-c-input");
    let data = {};

    for (let i = 0; i < inputFields.length; i++) {
      const element = inputFields[i];
      let field = element.id.split("-")[5];

      if (!element.value || element.value === "")
        return notify("warning", `${field} is required`);

      data[field] = element.value;
    }

    e.target.dataset.active = "false";

    submitContact(data, (status) => {
      e.target.dataset.active = "true";

      if (status === "success") {
        for (let i = 0; i < inputFields.length; i++) {
          const element = inputFields[i];
          element.value = "";
        }
      }
    });
  }

  function handleOnUnMute() {
    let btn = document.getElementById("home-youtube-video-btn");
    setMuted(false);
    if (btn) btn.dataset.active = "false";
  }

  return (
    <div className="grid-container">
      <header></header>

      <main className="first-main">
        <div className="home-top-section-wrap">
          <div className="home-top-section">
            <p className="Meet">CASI, The IoT Platform For Everyone</p>

            <div className="home-type-writer-wrap">
              <Typed
                strings={TypeWriterSentences}
                typeSpeed={40}
                backSpeed={50}
                backDelay={1000}
                showCursor={false}
                loop
              />
            </div>

            <div className="home-getstarted-button">
              <Link to="/pricing">
                <button
                  className="white-label-btn border-gradient border-gradient-purple"
                  type="button"
                >
                  Give CASI a Try!
                </button>
              </Link>
            </div>
          </div>

          <div className="home-top-section">
            <div className="home-youtube-video-wrap">
              <div
                id="home-youtube-video-btn"
                className="home-youtube-video-btn"
                onClick={handleOnUnMute}
              >
                <p className="home-youtube-video-btn-txt">Click To Unmute</p>
                <IoVolumeMuteOutline className="home-youtube-video-btn-icon" />
              </div>
              <ReactPlayer
                playing={Config.dev ? false : true}
                width="100%"
                height="100%"
                className="home-youtube-video"
                controls={true}
                muted={Muted}
                volume={1}
                url={`https://www.youtube.com/embed/dbL2fmUxxsw?rel=0`}
              />
              {/* <iframe id="home-youtube-video" width="100%" height="100%" src={`https://www.youtube.com/embed/dbL2fmUxxsw?rel=0&amp;autoplay=${Config.dev ? '0' : '1'}&mute=1`}></iframe> */}
            </div>
          </div>
        </div>

        <div className="center-text-wrap">
          <p className="home-device-note">Special Free Trial Offer</p>
        </div>

        <div className="center-text-wrap home-desc-section">
          <h1 className="home-top-desc-title">
            Are you an entrepreneur looking to manage your connected IoT devices
            remotely?
          </h1>
          <p className="make-easy">
            We’ve built a platform that makes it easy for entrepreneurs like you
            to remotely manage devices and data, and integrate with existing
            technology like Engine sensors,Temperature sensors, Pressure
            sensors, Human Machine interfaces (HMI), and Programmable Logic
            Controllers (PLC).
          </p>

          <h1 className="home-top-desc-title ">
            No Coding or Hiring Developers
          </h1>
          <div>
            <p>
              So you Can Focus On What Matters the Most-Growing your Business!
            </p>
            <p>
              It's a perfect tool for any entrepreneur who wants to connect
              their IoT Products in real-time while managing them from anywhere
              at anytime.
            </p>
          </div>
        </div>
        <div className="row-center2">
          <div className="money-back-link">
            <Link to="/pricing">
              Click here now to sign up for a 60 day money-back guarantee!
            </Link>
          </div>
        </div>

        <div className="home-social-proof-wrap">
          <div className="home-social-proof-top-section">
            <div className="home-s-p-t-s-grid">
              <h1 className="home-s-p-t-s-title">
                10,000+ of our users love our products.
              </h1>
              <p className="home-s-p-t-s-desc">
                We only provide great products combined with excellent customer
                service. See what our satisfied customers are saying about our
                services.
              </p>
            </div>
            <div className="home-s-p-t-s-grid">
              {Ratings.map((item, i) => (
                <div key={uuid()} className="home-s-p-t-s-rate-wrap">
                  <RatingStar id={uuid()} rating={item.rating} />
                  <p className="home-s-p-t-s-rate-desc">{item.text}</p>
                </div>
              ))}
            </div>
          </div>

          <div className="home-social-proof-review-section">
            {Reviews1.map((item, i) => (
              <div key={uuid()} className="home-s-p-r-s-card">
                <div className="home-s-p-r-s-c-top">
                  <img
                    src={item.image || "/images/profile-empty.png"}
                    alt="Review Author"
                    className="home-s-p-r-s-c-image"
                  />

                  <div className="home-s-p-r-s-c-t-author-wrap">
                    <p className="home-s-p-r-s-c-author">{item.author}</p>
                    <p className="home-s-p-r-s-c-verified">Verified Buyer</p>
                  </div>
                </div>
                <p className="home-s-p-r-s-c-description">“{item.review}”</p>
              </div>
            ))}
          </div>
        </div>

        <div className="center-text-wrap">
          <p className="home-features-carousel-heading">
            We have everything you need and more!
          </p>
        </div>

        <div id="home-features-carousel">
          <div className="home-features-carousel-item">
            <p className="home-features-carousel-item-title">
              CASI IoT Platform
            </p>

            <div className="home-features-carousel-item-content-wrap">
              <div className="home-features-carousel-item-content">
                <img
                  className="home-features-carousel-item-image"
                  alt="Carousel Item"
                  src="/images/Dashboard 01.png"
                />
              </div>

              <div className="home-features-carousel-item-content">
                <p className="home-features-carousel-item-desc">
                  CASI is an easy-to-use hardware & cloud software that allows
                  you to monitor almost anything with our simple web app. Our
                  hardware & software monitors everything from industrial
                  equipment like gas compressors, cnc machines, production
                  lines, vending machines, parking meters, HVAC units and more!
                  It can also be used for home security systems like door/window
                  sensors, smoke detectors and CO2 alarms as well as GPS
                  tracking for cars & trucks (and even pets).
                </p>
              </div>
            </div>
          </div>
        </div>

        <div className="how-it-works-section">
          <div className="h-i-w-s-top">
            <h1 className="h-i-w-s-title">How CASI IoT Works</h1>
            <div className="h-i-w-s-typing">
              <Typed
                strings={TypeWriter2Sentences}
                typeSpeed={40}
                backSpeed={50}
                backDelay={1000}
                showCursor={false}
                loop
              />
            </div>
          </div>

          <img
            src="/images/casi-gateway.jpg"
            alt="CASI IOT Gateway"
            className="h-i-w-s-image"
          />

          <p className="h-i-w-s-desc">
            Your device wants to talk and your services want to listen. On
            virtually any platform, anywhere in the world. Welcome to CASI! Now
            you can develop powerful meshing infrastructures without IT
            headaches for applications like wearables, remote monitoring systems
            and exercise/aerobic fitness web apps with no high cost or long
            developing time because we have created CASI
          </p>
          <p className="h-i-w-s-desc">
            The CASI is a cloud service that enables large and small enterprises
            to connect tens of millions of IoT devices and manage their data in
            real time -- all without the need for expensive enterprise hardware
            and software licensing.
          </p>

          <StepperSection active={false} />

          <div className="h-i-w-s-steps" data-active="true">
            <Carousel autoPlay showStatus={false}>
              <div className="h-i-w-s-step">
                <p className="h-i-w-s-s-title">
                  1. Connect your thing to our MQTT cloud broker
                </p>
                <p className="h-i-w-s-s-desc">
                  Bring your own device or use our devices to our cloud
                  infrastructure by connecting to our MQTT broker. The casi.io
                  MQTT broker can be used for testing, prototyping, and
                  deploying millions of things.. No need to set up the
                  infrastructure yourself; the casi.io mqtt brokers come with
                  SSL encryption out of the box. Join the rapidly growing
                  movement of millions of developers, engineers, businesses and
                  other organizations who are all coming together to create a
                  brighter future without software insanity. Connect your
                  equipment to our mqtt broker and subscribe to any topic in
                  order to have your devices give you updates whenever they're
                  invoked by other systems on the network. Customers can use
                  casi.io's MQTT Broker for companies looking for route
                  optimization, fleet management or any application where
                  strategic accountability is essential for success
                </p>
              </div>
              <div className="h-i-w-s-step">
                <p className="h-i-w-s-s-title">
                  2. Create Beautiful Dashboards, KPIs, Alarms, and Reports on
                  casi.io
                </p>
                <p className="h-i-w-s-s-desc">
                  Once your thing is connected to our MQTT broker you can create
                  real-time status data and managers track production KPIs and
                  trends in the Dashboard. The casi.io IoT platform is a turnkey
                  solution for any project that requires real-time data tracking
                  and forecasts. Our easy setup process offers a complete
                  package of data processing, storage, visualization, and
                  management tools all in one handy dashboard." Bring your data
                  to life with a full-featured dashboard builder that
                  automatically transforms raw content, data sets, and
                  visualizations into beautiful web pages you can share. Share
                  stunning dashboards from any link or publish them to almost
                  anywhere: WordPress site, Facebook, Twitter, Google Docs
                  spreadsheet... without requiring custom coding. Your company
                  has great data. We just need to teach you how to see it.
                  casi.io helps you create eye-catching, informative dashboards
                  that will help you visualize your findings and convert these
                  findings into valuable insights for your business.
                </p>
              </div>
              <div className="h-i-w-s-step">
                <p className="h-i-w-s-s-title">
                  3. Finally get your equipment “talking”!
                </p>
                <p className="h-i-w-s-s-desc">
                  Set up advanced analytics, reporting, and alerting on critical
                  equipment data on the casi.io iot cloud platform. Your
                  equipment is vitals-it’s essential to your operations. Ensure
                  it’s running at peak efficiency and cutting-edge monitoring
                  with casi.io, the newest IoT solution available today in all
                  industries. Realize cost savings in production output today
                  with casi.io iot!
                </p>
              </div>
            </Carousel>
          </div>
        </div>

        <div className="row-center2">
          <div className="money-back-link">
            <Link to="/pricing">
              Click here now to sign up for a 60 day money-back guarantee!
            </Link>
          </div>
        </div>

        <h2 className="will-help">CASI will help you...</h2>

        <div className="home-features-section">
          {FeaturesSections.map((item) => {
            return (
              <div
                key={Math.random()}
                data-swap={item.swap}
                className="features-section"
              >
                <img
                  src={item.image}
                  alt={item.alt || "Feature"}
                  className="feature-image"
                />

                <div className="feature-content">
                  <div className="home-help-fetaures" data-section="1">
                    <div className="home-help-fetaures-cards-wrap">
                      <div className="home-help-fetaures-card">
                        <img
                          src={item.icon}
                          alt="Home icon"
                          className="home-help-fetaures-image"
                        />
                        <p className="home-help-fetaures-title">{item.title}</p>
                        <p className="home-help-fetaures-desc">
                          {item.description}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
        </div>

        <div className="center-text-wrap">
          <h1 className="scale">
            &nbsp; Get a Head-Start to Quickly Scale Your Fleet of Devices&nbsp;
          </h1>
          <p className="casi-io-downtext">
            &nbsp; The CASI iot cloud Our platform gives enterprice decision
            makers access to all of their connected via our completely
            customizable dashboard without hassle or worry about security
            downtime or potential liability issues that can arise with managing
            these devices independently
          </p>
        </div>

        <div className="home-social-wrap-2">
          <div className="home-social-wrap-2-section">
            <h1 className="h-s-w-2-title">
              CASI is making the Internet of Things available for Everyone!
            </h1>
            <p className="h-s-w-2-desc">
              Read Why Business Owners, Manufacturers & OEMS, Fleet Operators,
              and Entrepreneurs love CASI.
            </p>
          </div>
          <div className="home-social-wrap-2-section home-social-wrap-2-review-section">
            {Reviews2.map((item) => (
              <div key={uuid()} className="h-s-w-2-card">
                <img
                  src={item.image || "/images/profile-empty.png"}
                  alt="Review Author"
                  className="h-s-w-2-c-image"
                />
                <div className="h-s-w-2-c-content">
                  <p className="h-s-w-2-c-author">{item.author}</p>
                  <p className="h-s-w-2-c-review"> “{item.review}”</p>
                  <RatingStar id={uuid()} rating={item.rating} />
                </div>
              </div>
            ))}
          </div>
        </div>

        <div className="home-contact-form-wrap">
          <h1 className="h-c-f-title">
            Having questions? Interested in a Live-Demonstration?
          </h1>
          <p className="h-c-f-desc">
            Please use the following form to leave us a request for a live demo.
            We will contact you immediately with a proposed date.
          </p>

          <div className="h-c-f-content">
            <div className="h-c-f-c-input-wrap">
              <p className="h-c-f-c-i-title">Name</p>
              <input
                id="h-c-f-c-i-name"
                type="text"
                className="h-c-f-c-input"
                placeholder="Name"
              />
            </div>
            <div className="h-c-f-c-input-wrap">
              <p className="h-c-f-c-i-title">Company</p>
              <input
                id="h-c-f-c-i-company"
                type="text"
                className="h-c-f-c-input"
                placeholder="Company"
              />
            </div>
            <div className="h-c-f-c-input-wrap">
              <p className="h-c-f-c-i-title">Telephone</p>
              <input
                id="h-c-f-c-i-phone"
                type="text"
                className="h-c-f-c-input"
                placeholder="Telephone"
              />
            </div>
            <div className="h-c-f-c-input-wrap">
              <p className="h-c-f-c-i-title">Email</p>
              <input
                id="h-c-f-c-i-email"
                type="email"
                className="h-c-f-c-input"
                placeholder="Email"
              />
            </div>
          </div>

          <div className="h-c-f-button-wrap">
            <button onClick={handleOnContactFormSubmit} className="button">
              Submit
            </button>
          </div>
        </div>
      </main>
    </div>
  );
}
