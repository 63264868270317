import { Checkbox, FormControlLabel, TextField } from "@mui/material";
import React, { useState } from "react";
import { userLogin } from "../../../functions/api/login";
import { registerUser } from "../../../functions/api/register";
import { exportUpdateUser } from "../../../layout/Navbar";
import { Link } from "react-router-dom";

export default function LoginSignUp({ onLoggedIn }) {
  const [Index, setIndex] = useState(0);

  function handleOnIndexChange(i) {
    setIndex(i);
  }

  function handleOnLogin() {
    let btn = document.getElementById("loginSignup-login-btn");
    let email = document.getElementById("loginSignup-email");
    let pwd = document.getElementById("loginSignup-password");
    let remember = document.getElementById("loginSignup-remember-checkbox");

    if (btn) btn.dataset.active = "false";

    userLogin(email.value, pwd.value, remember.checked, (status) => {
      if (btn) btn.dataset.active = "true";

      if (status === "success") {
        exportUpdateUser();

        if (onLoggedIn) onLoggedIn();
      }
    });
  }

  function handleOnSignUp() {
    let btn = document.getElementById("loginSignup-signup-btn");
    let name = document.getElementById("loginSignup-signup-name");
    let email = document.getElementById("loginSignup-signup-email");
    let pwd = document.getElementById("loginSignup-signup-password");
    let cpwd = document.getElementById("loginSignup-signup-cpassword");

    if (btn) btn.dataset.active = "false";

    registerUser(
      {
        email: email.value,
        name: name.value,
        password: pwd.value,
        cpassword: cpwd.value,
      },
      (status) => {
        if (status === "success") {
          if (btn) btn.dataset.active = "true";

          if (status === "success") {
            userLogin(email.value, pwd.value, false, (status) => {
              if (btn) btn.dataset.active = "true";

              if (status === "success") {
                exportUpdateUser();

                if (onLoggedIn) onLoggedIn();
              } else {
                if (btn) btn.dataset.active = "true";
              }
            });
            // handleOnIndexChange(1);
          }
        } else {
          if (btn) btn.dataset.active = "true";
        }
      }
    );
  }

  return (
    <div id="loginSignup">
      <img src="/images/logo.png" alt="Logo" className="sign-logo" />

      <div className="loginSignup-section" data-active={Index === 1}>
        <h1 className="loginSignup-title">Login</h1>
        <TextField
          label="Email"
          type="email"
          id="loginSignup-email"
          className="loginSignUp-input"
        />
        <TextField
          label="Password"
          type="password"
          id="loginSignup-password"
          className="loginSignUp-input"
        />
        <div className="loginSignup-remmeber">
          <FormControlLabel
            className="loginSignup-remmeber-label"
            control={
              <Checkbox
                id="loginSignup-remember-checkbox"
                name="loginSignup-remmeber"
                color="primary"
              />
            }
            label="Remember me"
          />
        </div>
        <button
          onClick={handleOnLogin}
          id="loginSignup-login-btn"
          className="button"
        >
          Login
        </button>

        <p className="loginSignUp-note">
          Don't have an accouunt ?{" "}
          <span
            onClick={() => handleOnIndexChange(0)}
            className="loginSignUp-link"
          >
            Sign Up
          </span>{" "}
        </p>
      </div>
      <div className="loginSignup-section" data-active={Index === 0}>
        <h1 className="loginSignup-title">Sign Up</h1>
        <TextField
          label="Name"
          type="text"
          id="loginSignup-signup-name"
          className="loginSignUp-input"
        />
        <TextField
          label="Email"
          type="email"
          id="loginSignup-signup-email"
          className="loginSignUp-input"
        />
        <TextField
          label="Password"
          type="password"
          id="loginSignup-signup-password"
          className="loginSignUp-input"
        />
        <TextField
          label="Confirm Password"
          type="password"
          id="loginSignup-signup-cpassword"
          className="loginSignUp-input"
        />

        <button
          id="loginSignup-signup-btn"
          onClick={handleOnSignUp}
          className="button"
        >
          Sign Up
        </button>

        <p className="loginSignUp-note">
          Already have an accouunt ?{" "}
          <span
            onClick={() => handleOnIndexChange(1)}
            className="loginSignUp-link"
          >
            Login
          </span>{" "}
        </p>
      </div>
    </div>
  );
}
