import * as React from "react";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import IconButton from "@mui/material/IconButton";
import { HiX } from "react-icons/hi";
const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

const BootstrapDialogTitle = (props) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle
      sx={{ m: 0, p: 2, textAlign: "left", fontSize: "2rem" }}
      {...other}
    >
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          color="secondary"
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
          }}
        >
          <HiX />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

export default function PopUp({
  fullscreen,
  state,
  onClose,
  title,
  maxWidth,
  children,
  logo = null,
}) {
  const handleClose = () => {
    onClose(false);
  };

  return (
    <div className="popup">
      <BootstrapDialog
        fullScreen={fullscreen || false}
        fullWidth={true}
        maxWidth={maxWidth || "sm"}
        aria-labelledby="customized-dialog-title"
        open={state}
        style={{ marginTop: "0", zIndex: 10000 }}
      >
        <BootstrapDialogTitle
          id="customized-dialog-title"
          onClose={handleClose}
          style={{ paddingTop: "0" }}
        >
          {logo}
          {title}
        </BootstrapDialogTitle>
        <DialogContent style={{ paddingBottom: "0" }}>{children}</DialogContent>
      </BootstrapDialog>
    </div>
  );
}
