module.exports = {
    add: (item) => {
        let cart = []
        let storage_cart = window.localStorage.getItem('cart')

        if(IsJson(storage_cart)) {
            storage_cart = JSON.parse(storage_cart)
            if(Array.isArray(storage_cart)) cart = storage_cart
        }

        cart.push(item)
        
        window.localStorage.setItem('cart', JSON.stringify(cart))
    },
    update: (id, item) => {
        let cart = []
        let storage_cart = window.localStorage.getItem('cart')

        if(IsJson(storage_cart)) {
            storage_cart = JSON.parse(storage_cart)
            if(Array.isArray(storage_cart)) cart = storage_cart
        }
        
        let index = cart.findIndex(i => i.id == id)

        if(index > -1) cart[index] = item

        window.localStorage.setItem('cart', JSON.stringify(cart))
    },
    remove: (id) => {
        let cart = []
        let storage_cart = window.localStorage.getItem('cart')

        if(IsJson(storage_cart)) {
            storage_cart = JSON.parse(storage_cart)
            if(Array.isArray(storage_cart)) cart = storage_cart
        }
        cart = cart.filter(i => i.id != id)

        window.localStorage.setItem('cart', JSON.stringify(cart))
    },
    get: (callback) => {
        let cart = []
        let storage_cart = window.localStorage.getItem('cart')

        if(IsJson(storage_cart)) {
            storage_cart = JSON.parse(storage_cart)
            if(Array.isArray(storage_cart)) cart = storage_cart
        }
        
        if(callback) callback(cart)
    }
}

function IsJson(string) {
    try {
        JSON.parse(string)
    } catch (error) {
        return false
    }

    return true
}