import { server } from "../../Config";
import { notify } from "../notify";
import axios from "axios";

export async function getDefaultPaymentMethod(callback) {
  let user = window.localStorage.getItem("gas-web-user");

  axios({
    method: "GET",
    url: server + "api/getUserDefaultPaymentMethod/" + user,
    withCredentials: true,
  })
    .then((res) => {
      if (res.data.data) callback([res.data.data]);
      else callback(null);
    })
    .catch((err) => {
      callback(null);
      if (err.response) {
        if (err.response.data.message) {
          notify("error", err.response.data.message);
        }
      }
    });
}

export async function purchaseHardware(data, callback) {
  axios({
    method: "POST",
    url: server + "api/purchaseHardware",
    withCredentials: true,
    data: data,
  })
    .then((res) => {
      notify(res.data.status, res.data.message);
      callback(res.data.status);
    })
    .catch((err) => {
      callback("failed");
      if (err.response) {
        if (err.response.data.message) {
          notify("error", err.response.data.message);
        }
      }
    });
}

export async function makeBundleSubscription(data, callback) {
  axios({
    method: "POST",
    url: server + "api/makeBundleSubscription",
    withCredentials: true,
    data: data,
  })
    .then((res) => {
      notify(res.data.status, res.data.message);
      callback(res.data.status);
      window.location.assign("https://cloud.casi.io");
      // window.location.assign("http://localhost:3001");
    })
    .catch((err) => {
      callback("failed");
      if (err.response) {
        if (err.response.data.message) {
          notify("error", err.response.data.message);
        }
      }
    });
}
export async function getUserSubscriptions(callback) {
  let user = window.localStorage.getItem("gas-web-user");

  axios({
    method: "GET",
    url: server + "api/getUserSubscriptions/" + user,
    withCredentials: true,
  })
    .then((res) => {
      callback(res.data.data);
    })
    .catch((err) => {
      callback(null);
      if (err.response) {
        if (err.response.data.message) {
          notify("error", err.response.data.message);
        }
      }
    });
}
