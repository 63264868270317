import React, { useState, useEffect } from "react";
import Card from "../components/Card";
import { getShop } from "../functions/api/shop";
import { server } from "../Config";

export default function Shop() {
  const [Data, setData] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      await getShop((response) => {
        setData(response);
      });
    };

    fetchData().catch(console.error);
  }, []);

  return (
    <div className="grid-container">
      <header></header>

      <main className="price-main">
        <h1 className="ready-price">Ready to get started</h1>

        <p className="price-do-you-text">Do You Want a Device from Us?</p>

        <div className="row-pay">
          {Data &&
            Data.map((item) => {
              let dataList = [];
              let imgUrl = "";
              if (item.image && item.image.includes("+")) {
                dataList = item.image.split("+");

                imgUrl = dataList[0];
              } else {
                imgUrl = item.image;
              }
              return (
                <Card
                  key={item.prodId}
                  title={item.prodId}
                  images={server + "api" + imgUrl}
                  price={item.price}
                  dollar="$"
                  alt={item.prodId}
                  link={item.prodId}
                />
              );
            })}
          {/* <Card
            title="UR32"
            images="./images/ur32.png"
            price="990"
            dollar="$"
            alt="ur32"
            link="UR32"
          />
          <Card
            title="UC34xx"
            images="./images/uc34xx.png"
            price="999"
            dollar="$"
            alt="uc3422"
            link="UC3422"
          />
          <Card
            title="UR35"
            images="./images/ur35.png"
            price="990"
            dollar="$"
            alt="ur35"
            link="UR35"
          />
        </div>
        <div className="row-pay">
          <Card
            title="Sim card"
            images="./images/sims/xff.png"
            price="5"
            dollar="$"
            alt="sim card"
            link="2FF"
          />
        </div>*/}
        </div>
      </main>
    </div>
  );
}
