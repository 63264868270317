import { Alert, Snackbar } from "@mui/material";
import React, { useState } from "react";
import { userLogout } from "./api/logout";

//success
//error
//warning
//info

export var notify;

export default function NotificationContainer() {
  const [Open, setOpen] = useState(false);
  const [Data, setData] = useState({
    message: "",
    type: "" || "error",
  });

  notify = (type, message, falseFormat) => {
    if (
      String(message).trim().toLowerCase() ===
        String("Auth failed").trim().toLowerCase() ||
      String(message).trim().toLowerCase() ===
        String("Token Must Be a String").trim().toLowerCase()
    ) {
      userLogout();
    } else {
      if (message && !falseFormat) message = capitalizeTxt(message);

      setData({ type: type, message: message });
      setOpen(true);
    }
  };

  function handleClose() {
    setOpen(false);
  }

  function capitalizeTxt(txt) {
    return txt.charAt(0).toUpperCase() + txt.slice(1).toLowerCase(); //or if you want lowercase the rest txt.slice(1).toLowerCase();
  }

  return (
    <Snackbar
      anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
      open={Open}
      autoHideDuration={2500}
      onClose={handleClose}
      style={{ zIndex: 40000 }}
    >
      <Alert
        onClose={handleClose}
        variant="filled"
        elevation={6}
        severity={Data.type}
        sx={{
          width: "100%",
          fontSize: "1.8rem",
          display: "flex",
          alignItems: "center",
          zIndex: "40001",
        }}
      >
        {Data.message}
      </Alert>
    </Snackbar>
  );
}
