import React from 'react';
import { Link } from 'react-router-dom';

export default function NotFound() {
    return (
        <div id="notFound" className="grid-container">
            <main className='not-found-main'>
                <h1 className="not-found-title">404</h1>
                <div className="not-found-desc">Oops! looks like the page you are looking for dose'nt exist or unavailable.</div>

                <Link to="/">
                    <button className="button">Home</button>
                </Link>
            </main>
        </div>
    );
}
