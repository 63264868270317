import React, { useState } from 'react';
import SelectContainer from './SelectContainer';
import uuid from 'react-uuid';
import Plants from './Plants';
import Details from './Details';
import ThankYou from './ThankYou';

export default function DetailForm() {

  const IndustTypeData = {
    title: ["To help us better understand your needs,", "what type(s) of industry are you working in ?"],
    types: [
      {
        key: '51e762cbe52270f27083',
        text: 'Manufacturing',
        image: "/images/detailsForm/manufac_ico.png",
        alt: '',
        checked: false
      },
      {
        key: 'f4a9cb76fbd5b2da216e',
        text: 'Oil & Gas',
        image: "/images/detailsForm/oilgasico.png",
        alt: '',
        checked: false
      },
      {
        key: 'a28dca91db0721f2267f',
        text: 'Environmental Protection',
        image: "/images/detailsForm/envio_ico.png",
        alt: '',
        checked: false
      },
      {
        key: '9187cc2340215f72b2b5',
        text: 'Transport',
        image: "/images/detailsForm/transport_ico.png",
        alt: '',
        checked: false
      },
      {
        key: '5e27ec0b98c36212abd4',
        text: 'Security & Monitoring',
        image: "/images/detailsForm/sec_ico.png",
        alt: '',
        checked: false
      },
      {
        key: '867b51f44c3fe9ccb488',
        text: 'Other',
        image: "/images/detailsForm/other.png",
        alt: '',
        checked: false
      },
    ]
  }
 
  const IntrestsTypeData = {
    title: ["What feature(s) are you most interested in ?"],
    types: [
      {
        key: 'fb1170ff149bbe753b65',
        text: 'Asset Tracking',
        image: "/images/detailsForm/asset_tracking.png",
        alt: '',
        checked: false
      },
      {
        key: '3735b90b81eec50a7975',
        text: 'Realtime Monitoring',
        image: "/images/detailsForm/real_time_monitoring.png",
        alt: '',
        checked: false
      },
      {
        key: '0768398483ca92d5b3c7',
        text: 'Security Camera',
        image: "/images/detailsForm/security_cameras.png",
        alt: '',
        checked: false
      },
      {
        key: '1ffbc059503de0886b89',
        text: 'Maintanance',
        image: "/images/detailsForm/maintanance.png",
        alt: '',
        checked: false
      },
      {
        key: '24931b8d79f7ffbe75f3',
        text: 'Remote Control',
        image: "/images/detailsForm/remote_control.png",
        alt: '',
        checked: false
      },
      {
        key: 'ec05604c240ea677acd9',
        text: 'Other',
        image: "/images/detailsForm/other.png",
        alt: '',
        checked: false
      },
    ]
  }

  const [IndustType, setIndustType] = useState(IndustTypeData.types)
  const [IntrestsType, setIntrestsType] = useState(IntrestsTypeData.types)
  const [Section, setSection] = useState('indust')
  const [PlantType, setPlantType] = useState('')

  function handleInsdustType(key) {
    let index = IndustType.findIndex(i => i.key === key)

    if(index > -1) {
      //IndustType[index].checked = !IndustType[index].checked
      
      let temp = IndustType.map(i => {
        if(i.key === key) {
          i.checked = !i.checked
        }else{
          i.checked = false
        }

        return i
      })

      setIndustType([...temp])
      
      if(temp[index].checked) {
        handleSectionChange('intrests')
      }
    }
  }
  
  function handleIntrestType(key) {
    let index = IntrestsType.findIndex(i => i.key === key)

    if(index > -1) {
      //IntrestsType[index].checked = !IntrestsType[index].checked
      
      let temp = IntrestsType.map(i => {
        if(i.key === key) {
          i.checked = !i.checked
        }else{
          i.checked = false
        }

        return i
      })
      
      setIntrestsType([...temp])
      
      if(temp[index].checked) {
        handleSectionChange('plants')
      }
    }
  }

  function handleSectionChange(val) {
    window.scrollTo(0, 0)
    setSection(val)
  }

  function handlePlantTypeChange(val) {
    setPlantType(val)

    handleSectionChange('details')
  }

  return (
    <div className="grid-container" id="detailedForm">
        <main className="content-container">
          <SelectContainer display={Section === 'indust'} title={IndustTypeData.title} onNext={() => handleSectionChange('intrests')} types={IndustType} setType={handleInsdustType} />
          <SelectContainer display={Section === 'intrests'} title={IntrestsTypeData.title} onBack={() => handleSectionChange('indust')} onNext={() => handleSectionChange('plants')} types={IntrestsType} setType={handleIntrestType} />
          <Plants display={Section === 'plants'} onBack={() => handleSectionChange('intrests')} onNext={() => handleSectionChange('details')} Plant={PlantType} setPlant={handlePlantTypeChange} />
          <Details display={Section === 'details'} PlantType={PlantType} IntrestsType={IntrestsType} IndustType={IndustType} onNext={() => handleSectionChange('thankyou')}  />
          <ThankYou display={Section === 'thankyou'}/>
        </main>
    </div>
  );
}
