import React from 'react';

export default function Quantity({id, quantity=0, onIncrease, onDecrease}) {
  return (
    <div className="quantity-item-counter">
        <button onClick={() => onDecrease(id)} className="quantity-item-counter-btn">
            -
        </button>
        <span className="quantity-item-count">{quantity}</span>
        <button onClick={() => onIncrease(id)} className="quantity-item-counter-btn">
            +
        </button>
    </div>
  );
}
