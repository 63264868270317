import React, { useEffect, useState } from "react";
import Payment from "../components/PopUp/Containers/DevicePayment/Payment";
import PopUp from "../components/PopUp/PopUp";
import LoginSignUp from "../components/PopUp/Containers/LoginSignUp";
import { getUserSubscriptions } from "../functions/api/billing";

export default function Pricing() {
  const [PricingBtn, setPricingBtn] = useState("year");
  const [Data, setData] = useState({});
  const [PyamentState, setPaymentState] = useState(false);
  const [paymentData, setpaymentData] = useState("");
  const [proceed, setProceed] = useState(false);
  const [LoginSignUpState, setLoginSignUpState] = useState(false);
  let user = window.localStorage.getItem("gas-web-user");
  useEffect(() => {
    const fetchPaymentsMethod = async () => {
      await getUserSubscriptions((data) => {
        let paymentdetails = [];

        let count = 0;
        let countElse = 0;
        if (data) {
          paymentdetails = data;
          paymentdetails.map((item) => {
            if (item.canceled === null && count === 0) {
              count++;
              paymentdetails.map((item) => {
                setpaymentData(item.package_data.name);
              });
            } else if (item.canceled !== null && countElse === 0) {
              countElse++;
              const today = new Date();
              const canceleDate = new Date(item.cancele_at * 1000);
              if (today > canceleDate) {
                paymentdetails.map((item) => {
                  setpaymentData(item.package_data.name);
                });
              }
            }
          });
        }
      });
    };
    if (user) {
      fetchPaymentsMethod().catch((err) => console.log(err));
    }
  }, []);

  function handlePricingButton(param) {
    setPricingBtn(param);
  }

  function handleLoginSignUpState(state) {
    setLoginSignUpState(state);
  }

  function handleOnBundleSubscription(price, sms, whitelabel, plan, devices) {
    if (paymentData) {
      setProceed(true);
    } else {
      setData({
        price: price,
        sms: sms,
        whitelabel: whitelabel,
        package: plan,
        devices: devices,
      });

      if (!user) {
        return handleLoginSignUpState(true);
      }

      handlePaymentState(true);
    }
  }
  function onLoggedIn() {
    handleLoginSignUpState(false);
    handlePaymentState(true);
  }

  function handlePaymentState(state) {
    setPaymentState(state);
  }

  function onSuccess() {
    handlePaymentState(false);
  }
  return (
    <div className="grid-container">
      <header></header>

      <main data-top-padding="false" className="price-main">
        <div className="wrap-price">
          <h3 className="which-plan"> Which plan is best for me ?</h3>
          <p className="moneyback60day">60 day Money Back Guarantee</p>
        </div>
        <div className="switch-wrapper">
          <p className="switch-to">
            Switch to yearly billing to get months free (Save16%)
          </p>
        </div>

        <div className="monthyearcontainer">
          <div className="grid-container-element">
            <button
              data-active={PricingBtn === "month"}
              onClick={() => handlePricingButton("month")}
              className="price-btn"
            >
              Monthly
            </button>
            <button
              data-active={PricingBtn === "year"}
              onClick={() => handlePricingButton("year")}
              className="price-btn"
            >
              Yearly
            </button>
          </div>
        </div>

        <div className="ready-container">
          <div className="packages-container">
            <div id="Started" className="packages-div">
              <p className="started-price"> Starter </p>
              {PricingBtn === "month" ? (
                <p className="Arial-20"> $59.00 Per Month </p>
              ) : (
                <p className="Arial-20">
                  {" "}
                  $49.00 Per Month <br />
                  <font className="Arial-2">Billed Annualy</font>{" "}
                </p>
              )}
              <button
                onClick={() =>
                  handleOnBundleSubscription(
                    PricingBtn === "month" ? 59 : 49,
                    0,
                    false,
                    PricingBtn === "month"
                      ? "889143ee0b567bee1c414d2ea26fc499"
                      : "5b9b9a46003d3969da3a15fa3bb70ba8",
                    5
                  )
                }
                type="button"
                className="buttonGetStarted"
              >
                Get Started
              </button>
              <p className="packages-text">5 Devices</p>
              <p className="packages-text">1 Month Data Retention</p>
              <p className="packages-text">E-mail Alerts</p>
              <p className="packages-text">UI Dashboard</p>
              <p className="packages-text">Device Management</p>
              <p className="packages-text">User Management</p>
              <p className="packages-text">Reports</p>
              <p className="packages-text">60 Day Money Back Guarantee</p>
            </div>

            <div id="Professional" className="packages-div">
              <p className="started-price"> Professional </p>
              {PricingBtn === "month" ? (
                <p className="Arial-20"> $159.00 Per Month </p>
              ) : (
                <p className="Arial-20">
                  {" "}
                  $132.00 Per Month <br />
                  <font className="Arial-2">Billed Annualy</font>{" "}
                </p>
              )}
              <button
                onClick={() =>
                  handleOnBundleSubscription(
                    PricingBtn === "month" ? 159 : 132,
                    200,
                    true,
                    PricingBtn === "month"
                      ? "63867472ed20d4a9457b6ba9d7de6e90"
                      : "300ec8508752819fe633ccd3e4f4b703",
                    50
                  )
                }
                type="button"
                className="buttonGetStarted"
              >
                Get Started
              </button>
              <p className="packages-text">50 Devices</p>
              <p className="packages-text">12 Months Data Retention</p>
              <p className="packages-text">E-mail Alerts</p>
              <p className="packages-text">200 Text Messages</p>
              <p className="packages-text">UI Dashboard</p>
              <p className="packages-text">Device Management</p>
              <p className="packages-text">User Management</p>
              <p className="packages-text">Reports</p>
              <p className="packages-text">Branded Web Portal OEM Platform</p>
              <p className="packages-text">60 Day Money Back Guarantee</p>
            </div>

            <div id="Advanced" className="packages-div">
              <p className="started-price"> Advanced </p>
              {PricingBtn === "month" ? (
                <p className="Arial-20"> $599.00 Per Month </p>
              ) : (
                <p className="Arial-20">
                  {" "}
                  $499.00 Per Month <br />
                  <font className="Arial-2">Billed Annualy</font>{" "}
                </p>
              )}
              <button
                type="button"
                className="buttonGetStarted border-gradient border-gradient-purple"
                onClick={() =>
                  handleOnBundleSubscription(
                    PricingBtn === "month" ? 599 : 499,
                    2000,
                    true,
                    PricingBtn === "month"
                      ? "c787c1daa4ff6e15c3afc014106d69fe"
                      : "3886ccc3bb937580ba69175d28640d02",
                    500
                  )
                }
              >
                Get Started
              </button>
              <p className="packages-text">Up to 500 Devices</p>
              <p className="packages-text">24 Months Data Retention</p>
              <p className="packages-text">E-mail Alerts</p>
              <p className="packages-text">2000 Text Messages</p>
              <p className="packages-text">UI Dashboard</p>
              <p className="packages-text">Device Management</p>
              <p className="packages-text">User Management</p>
              <p className="packages-text">Reports</p>
              <p className="packages-text">Branded Web Portal OEM Platform</p>
              <p className="packages-text">
                Fleet management client / partner/ Contract management
              </p>
              <p className="packages-text">All of the pro features</p>
              <p className="packages-text">Dedicated support engineer</p>
              <p className="packages-text">60 Day Money Back Guarantee</p>
            </div>
          </div>
          <div className="lorem">
            <p>
              The CASI cloud platform utilizes sensor data captured from the
              field to optimize any process. Whether you want to digitize your
              warehouse or data center, or connect to PLCs, HMIS, or any machine
              sensor....
            </p>
            <p className="lorem2">CASI Makes It Happen!</p>
          </div>
        </div>
      </main>

      <PopUp
        logo={
          <div>
            <img src="/images/logo.png" alt="Logo" className="payment-logo" />
          </div>
        }
        title="Payment"
        state={PyamentState}
        onClose={() => handlePaymentState(false)}
        fullscreen={true}
      >
        <Payment data={Data} onSuccess={onSuccess} />
      </PopUp>

      <PopUp
        title=""
        state={LoginSignUpState}
        onClose={() => handleLoginSignUpState(false)}
        fullscreen={true}
      >
        <LoginSignUp onLoggedIn={onLoggedIn} />
      </PopUp>
      <PopUp title="" state={proceed} onClose={() => setProceed(false)}>
        <div>
          <p>
            You already have a active <b>{paymentData}</b> package. if you want
            to <b>Upgrade</b> or <b>Downgrade</b>, please follow the link below
          </p>

          <p align="center">
            <a href="https://cloud.casi.io">Proceed</a>
          </p>
        </div>
      </PopUp>
    </div>
  );
}
