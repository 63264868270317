import { TextField } from "@mui/material";
import React from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { registerUser } from "../functions/api/register";
import { exportUpdateUser } from "../layout/Navbar";
import { userLogin } from "../functions/api/login";

export default function Signup() {
  const navigator = useNavigate();
  const location = useLocation();
  const state = location.state || {};

  function handleOnSignUp() {
    let btn = document.getElementById("signup-submit-btn");
    let name = document.getElementById("signup-input-fullname");
    let email = document.getElementById("signup-input-email");
    let pwd = document.getElementById("signup-input-password");
    let cpwd = document.getElementById("signup-input-confirm-password");

    if (btn) btn.dataset.active = "false";

    registerUser(
      {
        email: email.value,
        name: name.value,
        password: pwd.value,
        cpassword: cpwd.value,
      },
      (status) => {
        if (status === "success") {
          if (btn) btn.dataset.active = "true";

          if (status === "success") {
            userLogin(email.value, pwd.value, false, (status) => {
              if (btn) btn.dataset.active = "true";

              if (status === "success") {
                exportUpdateUser();

                if (state.redirectTo) {
                  navigator(state.redirectTo, {
                    state: {
                      redirectTo: null,
                      ...state,
                    },
                  });
                } else {
                  navigator("/");
                }
              } else {
                if (btn) btn.dataset.active = "true";
              }
            });
          } else {
            if (btn) btn.dataset.active = "true";
          }
        } else {
          if (btn) btn.dataset.active = "true";
        }
      }
    );
  }

  return (
    <div className="grid-container" id="signup-page">
      <main className="signup-main-container">
        <div className="signup-card">
          <div className="signup-card-top">
            <h1 className="signup-title">Sign Up</h1>
            <p className="signup-title-desc">
              You'll be up & running in 2 minutes
            </p>
          </div>

          <div className="signup-input-wrap">
            <TextField
              size="small"
              label="Full name"
              placeholder="Full name"
              type="text"
              className="signup-input"
              id="signup-input-fullname"
              fullWidth
            />
          </div>
          <div className="signup-input-wrap">
            <TextField
              size="small"
              label="Email"
              placeholder="Email"
              type="email"
              className="signup-input"
              id="signup-input-email"
              fullWidth
            />
          </div>
          <div className="signup-input-wrap">
            <TextField
              size="small"
              label="Password"
              placeholder="Password"
              type="password"
              className="signup-input"
              id="signup-input-password"
              fullWidth
            />
          </div>
          <div className="signup-input-wrap">
            <TextField
              size="small"
              label="Confirm Password"
              placeholder="Confirm Password"
              type="password"
              className="signup-input"
              id="signup-input-confirm-password"
              fullWidth
            />
          </div>

          <button
            data-active="true"
            id="signup-submit-btn"
            onClick={handleOnSignUp}
            className="button"
          >
            Sign Up
          </button>

          <div className="signup-link-wrap">
            <p className="signup-link-text">Already have an account ?</p>
            <Link className="signup-link" to="/login">
              Login
            </Link>
          </div>
        </div>
      </main>
    </div>
  );
}
