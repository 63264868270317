import React from 'react';
import { Link } from 'react-router-dom';

export default function ThankYou({display}) {
  return (
    <div id="detail-form-thankyou-container" className='detail-form-main-container' data-active={display} >
        <h1 className="detail-form-thankyou-title">Thank You!</h1>
        <p className="detail-form-thankyou-sub-title">We'll be in touch soon.</p>
        <p className="detail-form-thankyou-sub-title">Please check your mail for the credentials of the user account for <a rel="noreferrer" href="https://cloud.casi.io" target="_blank">cloud.casi.io</a>.</p>
        <Link to="/shop">
            <button className="button">Shop for IoT Device</button>
        </Link>
    </div>
  );
}
