import React from "react";
import { Link } from "react-router-dom";

export default function Features() {
  const Data = [
    {
      title: "Dashboard Editor",
      description: [
        "Create beautiful Dashboards with our no-code and easy-to-use Dashboard Editor.",
      ],
      image: "/images/DashboardEditor.png",
      alt: "Dashboard Editor",
      swap: true,
    },
    {
      title: "Create Public Dashboards",
      description: [
        " Create Dashboards and share them using a simple link that everyone can access without the need to register on CASI.",
      ],
      image: "images/createpublic dashboard.png",
      alt: "Create Public Dashboards",
      swap: false,
    },
    {
      title: "Asset Tracking",
      description: [
        "Do you know where your assets exactly are? rack your fleet of devices or even your fleet of vehicles Add an extra protection to your devices from theft and receive alerts when your device moves.",
      ],
      image: "images/createpublic dashboard.png",
      alt: "Asset Tracking",
      swap: true,
    },
    {
      title: "Create real-time dashboards and visualizations",
      description: [
        "Prevent downtime by spotting potential problems earlier with the customizable dashboard and alerts. You want to create dashboards and KPIs for your equipment, but you're strapped for time. What if there was a product that made it dead simple? That's what CASI is all about! With an easy-to-use interface and user permissions control, with CASI you'll be able to create beautiful dashboards and key performance indicators in seconds.",
      ],
      image: "/images/Dashboard Video.gif",
      alt: "Create real-time dashboards and visualizations",
      swap: false,
    },
    {
      title: "Advanced Alerts",
      description: [
        "No more worrying about not getting important equipment alerts! With CASI, you'll always be notified of any equipment alarms over text messages or email regardless if you are away from the workplace or on the move. Never miss an equipment alert again with instant notification over text message or email!",
      ],
      image: "/images/Industrial Dashboard 2 advanced alerts.png",
      alt: "Advanced Alerts",
      swap: true,
    },
    {
      title: "Reporting & Analytics",
      description: [
        "In the age of loT, your data is constantly being collected from sensors and devices. CASI helps you turn those interactions and activities into actionable insights and reports (aka analytics).",
        "With CASI, business users can:",
      ],
      image: "/images/repoat3.png",
      alt: "Reporting & Analytics",
      swap: false,
      points: [
        "View activity data in real-time",
        "Understand how people move through physical spaces",
        "Recognize patterns",
        "Monitor productivity by location",
        "Free up their time without losing valuable insights",
      ],
    },
  ];

  return (
    <div className="grid-container">
      <header></header>

      <main id="features-page">
        <div className="section feature-page-top-section">
          <div className="">
            <img
              className="plat-image"
              src="./images/Zerocode IOT.png"
              alt="Zerocode IOT"
            />
          </div>
          <div className="subsection">
            <h2 className="zero-code-1"> CASI Zero Code IoT Platform</h2>
            <div className="final-btn-1">
              <Link to="/pricing">
                <button
                  className="white-label-btn border-gradient border-gradient-purple"
                  type="button"
                >
                  Sign Up Now
                </button>
              </Link>
            </div>
          </div>
        </div>

        {Data.map((item) => {
          return (
            <div
              key={Math.random()}
              data-swap={item.swap}
              className="features-section"
            >
              <img
                src={item.image}
                alt={item.alt || "Feature"}
                className="feature-image"
              />

              <div className="feature-content">
                <h1 className="feature-title">{item.title}</h1>
                {item.description.map((txt) => {
                  return (
                    <p key={Math.random()} className="feature-desc">
                      {txt}
                    </p>
                  );
                })}
                {item.points ? (
                  <ul className="feature-points">
                    {item.points.map((point) => {
                      return (
                        <li className="feature-point" key={Math.random()}>
                          {point}
                        </li>
                      );
                    })}
                  </ul>
                ) : null}
              </div>
            </div>
          );
        })}
      </main>
    </div>
  );
}
