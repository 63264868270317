import { server } from "../../Config";
import { notify } from "../notify";
import axios from "axios";

export async function registerUser(data, callback) {
  axios({
    method: "POST",
    url: server + "api/register",
    data: {
      name: data.name,
      email: data.email,
      password: data.password,
      cpassword: data.cpassword,
      groupId: data.groupId,
      workspace: data.workspace,
    },
  })
    .then((res) => {
      notify(res.data.status, res.data.message);
      callback(res.data.status);
    })
    .catch((err) => {
      callback("failed");
      if (err.response) {
        if (err.response.data.message) {
          notify("error", err.response.data.message);
        }
      }
    });
}
