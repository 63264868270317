import React, { useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Quantity from '../components/Quantity';
import { deleteCartItem, getUserCart } from '../functions/api/cart';
import { exportUpdateCart } from '../layout/Navbar';
import LocalCart from '../functions/cart'
import PopUp from '../components/PopUp/PopUp';
import LoginSignUp from '../components/PopUp/Containers/LoginSignUp';

export default function Cart() {
    const navigate = useNavigate()
    const [Data, setData] = useState([]);
    const [Total, setTotal] = useState(0);
    const [LoginSignUpState, setLoginSignUpState] = useState(false);

    useMemo(() => {
        let total = 0;
        for (let i = 0; i < Data.length; i++) {
            const element = Data[i];
            total += parseFloat(element.price) * parseInt(element.quantity)
        }
        setTotal(total)
    }, [Data])

    useEffect(() => {
        let isMounted = true;

        if (isMounted) {
            LocalCart.get(data => {
                setData(data)
            })
        }

        return () => { isMounted = false }
    }, [])

    function handleOnItemRemove(id) {
        setData(Data.filter(i => i.id !== id))
        LocalCart.remove(id)
        exportUpdateCart()
    }

    function handleOnCountDecrease(id) {
        let index = Data.findIndex(i => i.id === id)

        if (index > -1) {
            let val = parseInt(Data[index].quantity) - 1
            if (val > 0) {
                Data[index].quantity = val
                setData([...Data])

                LocalCart.update(id, Data[index])
            }
        }
    }

    function handleOnCountIncrease(id) {
        let index = Data.findIndex(i => i.id === id)

        if (index > -1) {
            let val = parseInt(Data[index].quantity) + 1
            if (val > 0) {
                Data[index].quantity = val
                setData([...Data])

                LocalCart.update(id, Data[index])
            }
        }
    }

    function handleOnCheckOut() {
        let user = window.localStorage.getItem('gas-web-user')

        if (user) {
            redirectToCheckOut()
        } else {
            handleLoginSignUpState(true)
        }
    }

    function redirectToCheckOut() {
        let user = window.localStorage.getItem('gas-web-user')
        let temp = Data.map(i => {
            i['email'] = user
            return i
        })

        navigate('/checkout', {
            state: {
                total: Total,
                data: temp
            }
        })
    }

    function handleLoginSignUpState(state) {
        setLoginSignUpState(state)
    }

    function onLoggedIn() {
        handleLoginSignUpState(false)

        redirectToCheckOut()
    }

    return (
        <div id="cart-page" className='grid-container' >
            <main className='cart-main-container'>
                <h1 className="cart-main-title">Shopping Cart</h1>

                <div className="cart-item-container">

                    {
                        Data.length === 0 ?
                            <p className="cart-item-empty">No items in the cart yet</p>
                            :
                            Data.map(item => {
                                return (
                                    <div key={item.id} className="cart-item-wrap">
                                        <div className="cart-item-sub-wrap">
                                            <img src={item.hardware ? item.hardware.image : '/images/empty-image.png'} alt="Cart item" className="cart-item-image" />
                                            <div className="cart-item-details-wrap">
                                                <p className="cart-item-detail-title">{item.hardware ? item.hardware.name : 'Unknown'}</p>
                                                <p onClick={() => handleOnItemRemove(item.id)} className="cart-item-detail-remove">Remove</p>
                                            </div>
                                        </div>
                                        <div className="cart-item-sub-wrap">
                                            <Quantity id={item.id} quantity={item.quantity} onIncrease={handleOnCountIncrease} onDecrease={handleOnCountDecrease} />

                                            <div className="cart-item-price"><>$</>{item.price} USD</div>
                                        </div>
                                    </div>
                                )
                            })
                    }

                    <div className="cart-total-wrap">
                        <h2 className="cart-total"><>$</>{Total} USD</h2>
                        <p className="cart-total-note">Shipping & taxes calculated at checkout</p>

                        <button data-active={Data.length !== 0} onClick={handleOnCheckOut} className="button">Check out</button>
                    </div>

                </div>
            </main>

            <PopUp title="" state={LoginSignUpState} onClose={() => handleLoginSignUpState(false)}>
                <LoginSignUp onLoggedIn={onLoggedIn} />
            </PopUp>
        </div>
    )
}