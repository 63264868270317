import React, { useEffect, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import uuid from 'react-uuid';
import Payment from '../components/PopUp/Containers/HardwarePayment/Payment';
import PopUp from '../components/PopUp/PopUp';

export default function Checkout() {

    const navigate = useNavigate()
    const location = useLocation()
    const [Data, setData] = useState([]);
    const [PyamentState, setPaymentState] = useState(false);
    const [Shipping, setShipping] = useState('free');
    const [Total, setTotal] = useState(0);

    let state = location.state || {}

    useEffect(() => {
        if(Array.isArray(state.data)) setData(state.data)
        setTotal(state.total || 0)
    }, [state.data, state.total])
    
    function handleOnShipping(val) {
        setShipping(val)
    }

    function handlePaymentState(state) {
        setPaymentState(state)
    }

    function onSuccess() {
        handlePaymentState(false)

        setTimeout(() => {
            navigate('/thank-you', {
                state: {
                    displayThankYou: true
                }
            })
            //window.open("https://cloud.casi.io", '_blank').focus();
        }, 1000);
    }

    return (
        <div id="checkout-page" className='grid-container'>
            <main className='checkout-page-container'>
                <div className="checkout-grid-item">
                    <div className="checkout-shipping-item">
                        <div className="checkout-radio-wrap" onClick={() => handleOnShipping('free')} >
                            <input checked={Shipping === 'free'} onChange={() => handleOnShipping('free')} type="radio" name="checkout-shipping" className='checkout-shipping-radiobtn' />
                            <span>Free Shipping</span>
                        </div>
                        <span>Free</span>
                    </div>
                    <div className="checkout-shipping-item" onClick={() => handleOnShipping('standard')} >
                        <div className="checkout-radio-wrap">
                            <input checked={Shipping === 'standard'} onChange={() => handleOnShipping('standard')} type="radio" name="checkout-shipping" className='checkout-shipping-radiobtn' />
                            <span>Standard Shipping</span>
                        </div>
                        <span>$2.00</span>
                    </div>
                    
                    <div className="checkout-button-wrap">
                        <button onClick={() => handlePaymentState(true)}  className="button">Continue to payment</button>

                        <Link to="/cart" className='checkout-return-to-payment'>Return to cart</Link>
                    </div>
                </div>
                <div className="checkout-grid-item">
                    {
                        Data.map(item => {
                            if(!item?.id) return null

                            return (
                                <div key={uuid()} className="checkout-item-row-wrap checkout-item-bottom-border">
                                    <div className="checkout-item-image-wrap">
                                        <img src={item.hardware ? item.hardware.image : '/images/empty-image.png'} alt="Product" className="checkout-item-image" />
                                        <p className="checkout-item-title">{item.hardware ? item.hardware.name: 'Unknown'}</p>
                                    </div>
                                    <p className="checkout-item-price"><>$</>{item.price} x {item.quantity}</p>
                                </div>
                            )
                        })
                    }
                    {/* <div className="checkout-item-row-wrap checkout-item-bottom-border">
                        <input type="text" className="checkout-item-discount" placeholder='Discount code' />
                        <button className="button gray-btn">Apply</button>
                    </div> */}
                    <div className="checkout-item-column-wrap checkout-item-bottom-border">
                        <div className="checkout-item-meta-wrap">
                            <p className="checkout-item-meta-title">Subtotal</p>
                            <p className="checkout-item-meta-value"><>$</>{Total}.00</p>
                        </div>
                        <div className="checkout-item-meta-wrap">
                            <p className="checkout-item-meta-title">Shipping</p>
                            <p className="checkout-item-meta-value">{Shipping === 'free' ? 'Free' : '$2.00'}</p>
                        </div>
                        <div className="checkout-item-meta-wrap">
                            <p className="checkout-item-meta-title">Taxes (estimated)</p>
                            <p className="checkout-item-meta-value">$2.00</p>
                        </div>
                    </div>
                    <div className="checkout-item-row-wrap">
                        <p className="checkout-item-total">Total <span className='checkout-item-currency-wrap'><span className="checkout-item-currency">USD</span> <>$</>{Total + 2 + (Shipping === 'free' ? 0 : 2)}.00</span> </p>
                    </div>
                </div>
            </main>

            <PopUp title="Payment" state={PyamentState} onClose={() => handlePaymentState(false)}>
                <Payment shipping={Shipping} total={Total} hardware={Data} onSuccess={onSuccess} />
            </PopUp>

        </div>
    )
}
