import React from "react";
import Details from "./Details";

export default function Payment({ data, onSuccess }) {
  return (
    <div id="payment-container">
      <Details data={data} onSuccess={onSuccess} />
    </div>
  );
}
