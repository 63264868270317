import React, { useState } from 'react';
import Details from './Details';

export default function Payment({shipping, total, hardware, onSuccess}) {

  return (
      <div id="payment-container">
        <Details shipping={shipping} total={total} hardware={hardware} onSuccess={onSuccess} />
      </div>
  );
}
