import { server } from '../../Config'
import { notify } from '../notify'
import axios from 'axios';

export async function getUser(callback) {
    let user = window.localStorage.getItem('gas-web-user')

    if(!user){
        return
    }
    
    axios({
        method: 'POST',
        url: server+'api/getUser',
        withCredentials: true,
        data: {
            email: user
        }
    })
    .then(res => {
        if(!res.data.data){
            notify('info', 'No records')
            return
        }

        callback(res.data.data)
    })
    .catch(err => {
        if(err.response){
            if(err.response.data.message){
                notify('error', err.response.data.message)
            }
        }else{
            notify('error', 'Error')
        }
    })
}

export async function checkLogin(callback) {
    axios({
        method: 'GET',
        url: server+'api/checkLogin',
        withCredentials: true
    })
    .then(res => {
        callback(res.data.status)
    })
    .catch(err => {
        callback('failed')
        /* if(err.response){
            callback(err.response.statusText)
        } */
    })
}