import { server } from '../../Config'
import { notify } from '../notify'
import axios from 'axios';

export async function userLogin(email, password, remember, callback) {
    axios({
        method: 'POST',
        url: server+'api/login',
        withCredentials: true,
        data: {
            email: email,
            password: password,
            remember: remember
        }
    })
    .then(res => {
        if(res.data.login){
            let d = new Date()
            let expire = d.getTime() + parseInt(3600000)
            
            window.localStorage.setItem('gas-web-login', res.data.login)
            window.localStorage.setItem('gas-web-user', res.data.data.user)
            window.localStorage.setItem('gas-web-id', res.data.data.id)
            window.localStorage.setItem('gas-web-role', res.data.data.role)
            window.localStorage.setItem('gas-web-state', res.data.data.state)
            window.localStorage.setItem('gas-web-access', res.data.data.access)
            window.localStorage.setItem('gas-web-expires', expire)

            setTimeout(() => {
                callback('success')
            }, 500);
        }else{
            callback('failed')
            notify('info', 'unautharized access')
        }
    })
    .catch(err => {
        callback('failed')
        if(err.response){
            if(err.response.status === 401){
                notify('info', 'unautharized access')
            }
            else if(err.response.data.message){
                notify('error', err.response.data.message)
            }
        }
        else{
            notify('error', 'Error')
        }
    })
}
