import React from 'react'
import { Link } from 'react-router-dom'

function Card(props) {
    let newClassName = `color_bg ${props.alt}`
    let bg_img = `url(${props.images})`
    let { title, price, dollar, link } = props

    return (
        <Link to={'/product'} state={{product: link}} className='card'>
            <div className="wrapper">
                <div className={newClassName}></div>
                <div className="card_img" style={{ "backgroundImage" :bg_img }}></div>
                <div className="cardInfo">
                    <h2> {title} </h2>
                    <div className="action">
                        <div className='priceGroup'>
                        <p className="priceP">{dollar}{price}</p>
                        </div>
                     
                        <div className="cart" >
                            <svg className="outCart" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64">
                                    <path d="M2 6h10l10 40h32l8-24H16"></path>
                                    <circle cx="23" cy="54" r="4"></circle>
                                    <circle cx="49" cy="54" r="4"></circle>
                            </svg>
                        </div>
                       

                    </div>
                </div>
            </div>
          
        </Link>
    )
}

export default Card
