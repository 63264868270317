import { server } from '../../Config'
import { notify } from '../notify'
import axios from 'axios';

export async function applyCoupon(coupon, callback) {
    let user = window.localStorage.getItem('gas-web-user')
    
    axios({
        method: 'POST',
        url: server+'api/applyCoupon',
        withCredentials: true,
        data: {
            email: user,
            coupon: coupon
        }
    })
    .then(res => {
        callback(res.data.data)
    })
    .catch(err => {
        callback('failed')
        if(err.response){
            if(err.response.data.message){
                notify('error', err.response.data.message)
            }
        }else{
            notify('error', 'Error')
        }
    })
}