import React, { Component } from "react";

export default class Resources extends Component {
  render() {
    return (
      <div className="grid-container">
        <header></header>

        <main className="newLast-main">
          <h1 className="under">Comming soon!</h1>
        </main>
      </div>
    );
  }
}
