import React, { Fragment, useEffect, useState } from "react";
import Quantity from "../components/Quantity";
import { useNavigate, useLocation } from "react-router-dom";
import uuid from "react-uuid";
import { exportUpdateCart } from "../layout/Navbar";
import Cart from "../functions/cart";
import { getShop } from "../functions/api/shop";
import { server } from "../Config";

export default function Product() {
  const navigator = useNavigate();
  const location = useLocation();

  const [Data, setData] = useState([]);
  const [state, setState] = useState(location.state.product);
  const [LoginSignUpState, setLoginSignUpState] = useState(false);

  let schemedData = { products: {} };
  let resData = [];

  let data = {};
  useEffect(() => {
    const fetchData = async () => {
      await getShop((response) => {
        resData = response;

        let featuresData = "";
        let modelsData = "";

        try {
          {
            response &&
              resData.map((item) => {
                featuresData = item.features;
                modelsData = item.models;

                if (featuresData !== null) {
                  featuresData = featuresData.split(",");
                }

                if (modelsData !== null) {
                  modelsData = modelsData.split(",");
                }

                try {
                  data = JSON.parse(JSON.parse(item.data));
                } catch (err) {
                  data = data;
                }

                schemedData.products[item.prodId] = {
                  prodId: item.prodId,
                  name: item.name,
                  quantity: item.quantity,
                  image: item.image,
                  price: item.price,
                  description: item.description,
                  gps: item.gps === "true",
                  features: featuresData,
                  models: modelsData,
                  dataSheet: item.datasheet,
                  mid_image: item.mid_image,
                  data: data,
                };
              });

            if (state && schemedData) {
              if (schemedData.products) {
                resData.map((item) => {
                  if (String(item.prodId).trim() === String(state).trim()) {
                    setData(schemedData.products[item.prodId]);
                  }
                });
              }
            }
          }
        } catch (err) {
          console.log("err", err);
        }
      });
    };

    fetchData().catch(console.error);
  }, [state]);

  function handleOnCountDecrease() {
    let val = parseInt(Data.quantity) - 1;
    if (val > 0) {
      Data.quantity = val;
      setData({ ...Data });
    }
  }

  function handleOnCountIncrease() {
    let val = parseInt(Data.quantity) + 1;
    if (val > 0) {
      Data.quantity = val;
      setData({ ...Data });
    }
  }

  function handleOnProductGPSChange(e) {
    let val = e.target.value;
    Data.gps = val;
  }

  function handleOnProductModelChange(e) {
    navigator("/product", {
      state: { product: e.target.value },
      replace: true,
    });

    setState(e.target.value);
  }

  function handleOnAddToCart() {
    let data = {
      id: uuid(),
      hardware: Data,
      name: Data.name,
      price: Data.price,
      quantity: Data.quantity,
    };

    Cart.add(data);
    exportUpdateCart();
  }

  function handleLoginSignUpState(state) {
    setLoginSignUpState(state);
  }

  let dataList = [];
  let imgUrl = "";
  if (Data.image && Data.image.includes("+")) {
    dataList = Data.image.split("+");

    imgUrl = dataList[0];
  } else {
    imgUrl = Data.image;
  }

  return (
    <div className="grid-container product-page ">
      <main className="product-main-container">
        <div className="product-grid">
          <div className="product-grid-item">
            <img
              src={server + "api" + imgUrl}
              alt="Product"
              className="product-image"
            />
          </div>

          <div className="product-grid-item">
            <p className="product-title">{Data.name}</p>
            <p className="product-price">
              Price: <>$</>
              {Data.price} USD
            </p>

            {Data.gps ? (
              <div className="product-content-wrap">
                <p className="product-sub-title">Type</p>
                <select
                  defaultValue={true}
                  onChange={handleOnProductGPSChange}
                  className="product-gps-select"
                >
                  <option value={true} className="product-gps-select-elemet">
                    With GPS
                  </option>
                  <option value={false} className="product-gps-select-elemet">
                    Without GPS
                  </option>
                </select>
              </div>
            ) : null}
            {Array.isArray(Data.models) ? (
              <div className="product-content-wrap">
                <p className="product-sub-title">Model</p>
                <select
                  value={state}
                  onChange={handleOnProductModelChange}
                  className="product-gps-select"
                >
                  {Data.models.map((model) => {
                    return (
                      <option
                        key={uuid()}
                        value={model}
                        className="product-gps-select-elemet"
                      >
                        {model}
                      </option>
                    );
                  })}
                </select>
              </div>
            ) : null}
            {Data.dataSheet ? (
              <div className="product-content-wrap">
                <p className="product-sub-title">Data sheet</p>
                <a href={Data.dataSheet} target="_blank">
                  Click here see the data sheet
                </a>
              </div>
            ) : null}
            <div className="product-content-wrap">
              <p className="product-sub-title">Quantity</p>
              <div className="product-quantity-wrap">
                <Quantity
                  id={null}
                  quantity={Data.quantity}
                  onIncrease={handleOnCountIncrease}
                  onDecrease={handleOnCountDecrease}
                />
                <button onClick={handleOnAddToCart} className="button">
                  Add to cart
                </button>
              </div>
            </div>
          </div>
        </div>

        <div className="product-grid product-grid-center">
          <div className="product-grid-item">
            <p className="product-description">{Data.description}</p>
          </div>
          <div className="product-grid-item">
            <ul className="product-features">
              {Array.isArray(Data.features)
                ? Data.features.map((feature) => {
                    return (
                      <li key={uuid()} className="product-feature-item">
                        {feature}
                      </li>
                    );
                  })
                : null}
            </ul>
          </div>
        </div>

        {Data.prodId === "UR35" ? (
          <div className="product-image-grid">
            <img
              src="/images/Dashboard 01.png"
              alt="Product"
              className="product-image-grid-image"
            />
            <img
              src="/images/arrow-left.png"
              alt="Product"
              className="product-image-grid-image product-image-grid-arrow-image product-image-grid-arrow-left-image"
            />
            <img
              src={server + "api" + Data.image}
              alt="Product"
              className="product-image-grid-image product-image-grid-center-image "
            />
            <img
              src="/images/arrow-right.png"
              alt="Product"
              className="product-image-grid-image product-image-grid-arrow-image product-image-grid-arrow-right-image"
            />
            <img
              src="/images/Dashboard 01.png"
              alt="Product"
              className="product-image-grid-image"
            />
          </div>
        ) : Data.mid_image ? (
          <div className="product-image-grid">
            <img
              src={server + "api" + Data.mid_image}
              alt="Product"
              className="product-image-grid-image2"
            />
          </div>
        ) : null}

        <div className="products-details-table-wrap">
          <table className="products-details-table">
            <tbody>
              {Data.data
                ? Object.keys(Data.data).map((item) => {
                    return (
                      <Fragment key={uuid()}>
                        {Object.keys(Data.data[item]).map((subItem, i) => {
                          return (
                            <tr
                              key={uuid()}
                              className="product-details-table-row"
                            >
                              <td
                                data-active={i === 0}
                                rowSpan={Object.keys(Data.data[item]).length}
                                className="product-details-table-data"
                              >
                                <div className="product-table-cell-data">
                                  {item}
                                </div>
                              </td>
                              <td className="product-details-table-data">
                                <div className="product-table-cell-data">
                                  {subItem}
                                </div>
                              </td>
                              <td className="product-details-table-data">
                                <div className="product-table-cell-data">
                                  {Data.data[item][subItem]}
                                </div>
                              </td>
                            </tr>
                          );
                        })}
                      </Fragment>
                    );
                  })
                : null}
            </tbody>
          </table>
        </div>
      </main>
    </div>
  );
}
