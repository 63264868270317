const {
  REACT_APP_MAP_KEY,
  REACT_APP_SERVER,
  REACT_APP_STRIPE_PK,
  REACT_APP_DRIFT_APPID,
  REACT_APP_DEVELOPMENT,
} = process.env;

module.exports = {
  map_key: REACT_APP_MAP_KEY,
  server: REACT_APP_SERVER,
  stripe_pk: REACT_APP_STRIPE_PK,
  drift: REACT_APP_DRIFT_APPID,
  dev: REACT_APP_DEVELOPMENT || false,
};
