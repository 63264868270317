import React, { useEffect, useState } from 'react';
import { loadStripe } from '@stripe/stripe-js';
import {
    Elements,
    useStripe,
    useElements,
    CardElement
} from "@stripe/react-stripe-js";
import { notify } from '../functions/notify';
import ResponsiveFontSize from './ResponsiveFontSize';
import Config from '../Config';
import { getDefaultPaymentMethod } from '../functions/api/billing';
import { useCardBrand, images } from 'react-card-brand';

export var exportSubmitCard;

const useOptions = () => {
    const fontSize = ResponsiveFontSize();
    const options = {
        hidePostalCode: true,
        style: {
            base: {
                color: "#424770",
                letterSpacing: "0.025em",
                fontFamily: "Source Code Pro, monospace",
                "::placeholder": {
                    color: "#aab7c4"
                }
            },
            invalid: {
                color: "#dc3545"
            }
        }
    }

    return options;
};

const stripePromise = loadStripe(Config.stripe_pk);

export default function Card({ callback, hidebtn, hidetitle, defaultPayment, hideSelectable, hideNewCardtitle, btnContent }) {

    return (
        <div className="card_form_details">
            <h3 data-hide={hidetitle} className="title">Card Details</h3>
            <Elements stripe={stripePromise}>
                <CheckOutForm
                    callback={callback}
                    hidebtn={hidebtn}
                    defaultPayment={defaultPayment}
                    hideSelectable={hideSelectable}
                    hideNewCardtitle={hideNewCardtitle}
                    btnContent={btnContent} />
            </Elements>
        </div>
    )
}


function CheckOutForm({ callback, hidebtn, id, defaultPayment, hideSelectable, hideNewCardtitle, btnContent }) {

    const stripe = useStripe();
    const elements = useElements();
    const options = useOptions();
    const { getSvgProps } = useCardBrand();
    const [PaymentMethods, setPaymentMethods] = useState([])
    const [SelectedCard, setSelectedCard] = useState('')
    const [AllowNewCard, setAllowNewCard] = useState(true)

    useEffect(() => {
        let isMounted = true

        if (isMounted && !hideSelectable) fetchDefaultPaymentMethod()

        return () => {
            isMounted = false
        }
    }, [hideSelectable])

    exportSubmitCard = async (data) => {
        handleSubmit(null, data)
    }

    const handleSubmit = async (e, data) => {
        if (!stripe || !elements) {
            // Stripe.js has not loaded yet. Make sure to disable
            // form submission until Stripe.js has loaded.
            callback(false, data)
            return;
        }

        if (SelectedCard) return callback({ token: { id: SelectedCard } }, data)

        let card = elements.getElement(CardElement)

        if (card) {
            if (e) e.target.dataset.active = 'false';

            const payload = await stripe.createToken(card);

            if (payload) {
                if (payload.error) {
                    callback(false, data)
                    notify('error', payload.error.message)
                    return;
                }

                if (e) e.target.dataset.active = 'true'
                return callback(payload, data)
            }
        };

        return callback(false, data)
    }

    function fetchDefaultPaymentMethod() {
        getDefaultPaymentMethod((data) => {
            if (data) {
                setAllowNewCard(false)
                setSelectedCard(data[0].id)
                setPaymentMethods(data)
            }
        })
    }

    function handleOnCardItemSelect(item) {
        if ((SelectedCard === item.id) && !defaultPayment) {
            setSelectedCard('')
        } else {
            setSelectedCard(item.id)
        }
    }

    return (
        <div className="card_main_wrap" >
            <div className="card-section" data-active={PaymentMethods.length > 0} >
                <p className="info">Select a Card</p>
                <div className="card_list" data-active={!hideSelectable}>
                    {
                        PaymentMethods.map((item) => {
                            return (
                                <div key={item.id} data-active={item.id === SelectedCard} className="card_item" onClick={(e) => handleOnCardItemSelect(item)} >
                                    <span className="card_sub_item"><svg {...getSvgProps({ type: item.brand.toLowerCase(), images })} /> {item.brand} </span>
                                    <span className="card_sub_item">XXXX-{item.last4}</span>
                                </div>
                            )
                        })
                    }
                </div>
            </div>
            <div className="card-section" data-active={AllowNewCard}>
                <p className="info" data-active={!hideNewCardtitle}>Use a New Card</p>
                <div className="card_wrap" data-disabled={SelectedCard !== ''}>
                    <CardElement options={options} />
                </div>
            </div>

            <div className="button-wrap" data-hide={hidebtn}>
                <button onClick={(e) => handleSubmit(e)} id={id} className="button" type="submit" data-active={!!stripe} disabled={!stripe}>
                    {btnContent || 'Pay'}
                </button>
            </div>
        </div>
    )
}

