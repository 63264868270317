import React from 'react';

export default function Plants({display, onBack, onNext, setPlant, Plant}) {

    function handleOnNext() {
        if(Plant !== '') onNext()
    }

    return (
        <div id="detail-form-plants-container" className='detail-form-main-container' data-active={display} >
            <div className="detail-form-top">
                <h1 className="detail-form-title">We can definietly help with that!</h1>
                <h1 className="detail-form-title">How many plants do you want to monitor or control ?</h1>
            </div>

            <div className="detail-form-content-container">
                <div className="detail-form-plants-wrap-container">
                    <div className="detail-form-plants-wrap" onClick={() => setPlant('1-2')}>
                        <input checked={Plant === '1-2'} onChange={() => setPlant('1-2')} className='detail-form-plant-radio-btn' type="radio" name="detail-form-plant"/>
                        <label className='detail-form-plant-radio-btn-label'>1 - 2</label>
                    </div>
                    <div className="detail-form-plants-wrap" onClick={() => setPlant('3-10')}>
                        <input checked={Plant === '3-10'} onChange={() => setPlant('3-10')} className='detail-form-plant-radio-btn' type="radio" name="detail-form-plant"/>
                        <label className='detail-form-plant-radio-btn-label'>3 - 10</label>
                    </div>
                    <div className="detail-form-plants-wrap" onClick={() => setPlant('60+')}>
                        <input checked={Plant === '60+'} onChange={() => setPlant('60+')} className='detail-form-plant-radio-btn' type="radio" name="detail-form-plant"/>
                        <label className='detail-form-plant-radio-btn-label'>60 +</label>
                    </div>
                </div>
            </div>
            
            <div className="detail-form-button-wrap">
                {
                    onNext ? <button onClick={handleOnNext} className="detail-form-button">Next</button> : null
                }
                {
                    onBack ? <button onClick={onBack} className="detail-form-button">Back</button> : null
                }
            </div>
        </div>
    )
}
