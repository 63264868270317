import React, { useEffect } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';

export default function ThankYou() {
  
  const navigate = useNavigate()
  const location = useLocation()
  let state = location.state || {}

  useEffect(() => {
    let isMounted = true
    
    if(isMounted) {
      if(!state.displayThankYou) {
        /* navigate('/', {
          state: {}
        }) */
      }
    }
  
    return () => {
      isMounted = false
    }
  }, [state.displayThankYou])
  
  return (
    <div id="thank-you" data-active={true} >
        <h1 className="detail-form-thankyou-title">Thank You!</h1>
        <p className="detail-form-thankyou-sub-title">We'll be in touch soon.</p>
        <Link to="/shop">
            <button className="button">Shop for IoT Device</button>
        </Link>
    </div>
  );
}
