import "bootstrap/dist/css/bootstrap.min.css";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import React, { useLayoutEffect, useEffect } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  useLocation,
} from "react-router-dom";
import Config from "./Config";
import Drift from "react-driftjs";

import Navbar from "./layout/Navbar";
import FooterContainer from "./layout/footer";
import StickyFooter from "./layout/StickyFooter";

import Home from "./pages/Home";
import Features from "./pages/Features";
import ContactUs from "./pages/ContactUs";
import Shop from "./pages/Shop";
import Resources from "./pages/Resources";
import DetailForm from "./pages/DetailForm/DetailForm";
import Checkout from "./pages/Checkout";
import Cart from "./pages/Cart";
import Product from "./pages/Product";
import Login from "./pages/Login";
import Signup from "./pages/Signup";
import NotificationContainer from "./functions/notify";
import NotFound from "./pages/NotFound";
import { checkLogin } from "./functions/api/user";
import { userLogout } from "./functions/api/logout";
import Pricing from "./pages/Pricing";
import ThankYou from "./pages/ThankYou";

function App() {
  const Wrapper = ({ children }) => {
    const location = useLocation();
    useLayoutEffect(() => {
      if (!Config.dev) document.documentElement.scrollTo(0, 0);
    }, [location.pathname, Config]);
    return children;
  };

  let user = window.localStorage.getItem("gas-web-user");

  useEffect(() => {
    if (user) {
      checkLogin((data) => {
        if (data.status === "failed") {
          userLogout();
        }
      });
    }
  }, [user]);

  return (
    <div className="App">
      <Router>
        <Navbar />
        <Wrapper>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/features" element={<Features />} />
            <Route path="/contactus" element={<ContactUs />} />
            <Route path="/shop" element={<Shop />} />
            <Route path="/resources" element={<Resources />} />
            <Route path="/product" element={<Product />} />
            <Route path="/pricing" element={<Pricing />} />
            <Route path="/details" element={<DetailForm />} />
            <Route path="/checkout" element={<Checkout />} />
            <Route path="/cart" element={<Cart />} />
            <Route path="/login" element={<Login />} />
            <Route path="/signup" element={<Signup />} />
            <Route path="//thank-you" element={<ThankYou />} />
            <Route path="*" element={<NotFound />} />
          </Routes>
        </Wrapper>
        <Drift
          appId={Config.drift}
          style={{
            overflow: "visible !important",
          }}
        />
        <NotificationContainer />
        <FooterContainer />
        <StickyFooter />
      </Router>
    </div>
  );
}

export default App;
