import { server } from '../../Config'
import { notify } from '../notify'
import axios from 'axios';

export async function getItemPackage(id, callback) {
    axios({
        method: 'GET',
        url: server+'api/getItemPackage/' + id,
        withCredentials: true
    })
    .then(res => {
        if(!res.data.data){
            notify('info', 'No records')
            return
        }

        callback(res.data.data)
    })
    .catch(err => {
        if(err.response){
            if(err.response.data.message){
                notify('error', err.response.data.message)
            }
        }else{
            notify('error', 'Error')
        }
    })
}