import React, { useState } from 'react';
import { createEStoreUserData } from '../../functions/api/eStore';
import { notify } from '../../functions/notify';

export default function Details({display, PlantType, IntrestsType, IndustType, onNext}) {
    const [Index, setIndex] = useState(0);

    function onSubmit() {
        let inputFields = document.getElementsByClassName('detail-form-details-input')
        let data = {}

        for (let i = 0; i < inputFields.length; i++) {
            const element = inputFields[i];

            let field = element.id.split("-")[4]
            if(!element.value || element.value === '') return notify('warning', `${field} is required`)

            data[field] = element.value
        }

        if(PlantType)  data['plants'] = PlantType
        if(IntrestsType)  data['intrests'] = IntrestsType.map(i => i.checked ? i.text : null)
        if(IndustType)  data['industry'] = IndustType.map(i => i.checked ? i.text : null)
        data['message'] = 'null'
        
        createEStoreUserData(data, (status) => {
            if(status === 'success') {
                onNext()
            }
        })
    }

    function handleOnIndexChange(index) {
        let inputFields = document.getElementsByClassName('detail-form-details-input')

        if(index > Index) {
            for (let i = 0; i < inputFields.length; i++) {
                const element = inputFields[i];
                let valid = [['firstname', 'lastname'],  ['email', 'company'], ['phone']]
    
                let field = element.id.split("-")[4]
    
                if(valid[index - 1].includes(field)) {
                    if(!element.value || element.value === '') return notify('warning', `${field} is required`)
                }
            }
        }

        setIndex(index)
    }
    
    return (
        <div className='detail-form-main-container' id="detail-form-details-container" data-active={display} >
            <div className="detail-form-top">
                <h1 className="detail-form-title">You're almost there</h1>
            </div>

            <div className="detail-form-content-container">
                <div className="detail-form-details-container" data-active={Index === 0}>
                    <div className="detail-form-details-input-wrap">
                        <p className="detail-form-details-title">First name</p>
                        <input type="text" className="detail-form-details-input" id="detail-form-details-input-firstname" placeholder='First name' />
                    </div>
                    <div className="detail-form-details-input-wrap">
                        <p className="detail-form-details-title">Last name</p>
                        <input type="text" className="detail-form-details-input" id="detail-form-details-input-lastname" placeholder='Last name' />
                    </div>
                    <div className="detail-form-button-wrap">
                        <button onClick={() => handleOnIndexChange(1)} className="detail-form-button">Next</button>
                    </div>
                </div>
                <div className="detail-form-details-container"  data-active={Index === 1}>
                    <div className="detail-form-details-input-wrap">
                        <p className="detail-form-details-title">Working email</p>
                        <input type="email" className="detail-form-details-input" id="detail-form-details-input-email" placeholder='Working email' />
                    </div>
                    <div className="detail-form-details-input-wrap">
                        <p className="detail-form-details-title">Company name</p>
                        <input type="text" className="detail-form-details-input" id="detail-form-details-input-company" placeholder='Company name' />
                    </div>
                    <div className="detail-form-button-wrap">
                        <button onClick={() => handleOnIndexChange(0)}  className="detail-form-button">Back</button>
                        <button onClick={() => handleOnIndexChange(2)} className="detail-form-button">Next</button>
                    </div>
                </div>
                <div className="detail-form-details-container"  data-active={Index === 2}>
                    <div className="detail-form-details-input-wrap">
                        <p className="detail-form-details-title">Phone</p>
                        <input type="text" className="detail-form-details-input" id="detail-form-details-input-phone" placeholder='Phone' />
                    </div>
                    <div className="detail-form-button-wrap">
                        <button onClick={() => handleOnIndexChange(1)}  className="detail-form-button">Back</button>
                        <button onClick={onSubmit} className="detail-form-button">Submit</button>
                    </div>
                </div>
            </div>

        </div>
    )
}
