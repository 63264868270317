import React, { Component } from "react";
import SimpleMap from "../components/SimpleMap";

export default class About extends Component {
  render() {
    return (
      <div className="grid-container">
        <header></header>

        <main className="contact-main">
          <h1 className="contact-h1">Contact Us</h1>
          <p className="contact-text">
            The CASI iot cloud software offers a comprehensive suite of
            interconnected cloud-based services for enterprises looking to
            automate their processes and infrastructure using advanced,
            consumerized IoT technologies.
          </p>
          <p className="contact-text">
            Question?
            <br />
            Feel free to contact us for any inquiry.
          </p>

          <div className="wrap-final-container-1-1">
            <div className="final-container-1-1">
              <div className="form-wrapper-new">
                <div className="last-4-cntct">
                  <p className="p1-book-on">Call Us</p>
                  <p className="bluenumber">
                    <a href="tel:+12149005381" className="bluenumber">
                    (214) 900-5381
                    </a>
                  </p>
                  <p className="seenumbers">
                    <font className="p1-book-on">Email Us</font>
                    <br />
                    <a href="mailto:support@casi.io" className="bluenumber">
                      support@casi.io
                    </a>
                  </p>
                  <p className="seenumbers">
                    <font className="p1-book-on">Hours</font>
                    <br />
                    Monday - Saturday: 9 AM - 4 PM
                  </p>
                </div>
              </div>
              <div className="form-wrapper-new">
                <div className="last-4-cntct">
                  <p className="seenumbers">
                    <font className="p1-book-on">Address</font>
                    <br />
                    1400 S Sherman St STE 116 Richardson
                    <br />
                    TX 75081 USA
                  </p>
                  <p className="seenumbers">
                    <font className="p1-we-are-available">
                      We are available now!
                    </font>
                    <br />
                  </p>
                  <div className="final-btn-1">
                    <a href="/details" target="_blank">
                      <button
                        className="white-label-btn border-gradient border-gradient-purple"
                        type="button"
                      >
                        Drop Us a Line
                      </button>
                    </a>
                  </div>
                  <p className="seenumbers"></p>
                </div>
              </div>
            </div>
          </div>

          <p className="should-text">Where Are We?</p>
          <div className="wrapper-contact-image">
            <SimpleMap />
          </div>
        </main>
      </div>
    );
  }
}
