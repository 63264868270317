import React from "react";
import { Link } from "react-router-dom";

const StickyFooter = () => (
  <>
    <div className="sticky-footer mobile-footer">
      <div>
        <a href="/details">
          <p className="footer-attention-text">Start Your 15-day Trial Now!</p>
        </a>
      </div>
      <div className="mobile-footer-top-wrap">
        {/* <a href="/"> <img className="logo-img1" src="./images/CasiioLogo-white.png" alt="Casio Logo"/> </a> */}
        <Link to="/pricing">
          <button className="white-signup-btn">Get CASI Now</button>
        </Link>
      </div>
    </div>
    <div className="sticky-footer desktop-footer">
      {/* <div>
                <img className="logo-img1" src="./images/CasiioLogo-white.png" alt="Casio Logo"/>
            </div> */}
      <div>
        <a href="/details">
          <p className="footer-attention-text">Start Your 15-day Trial Now!</p>
        </a>
      </div>
      <div className="sticky-footer-signup-btn-wrap">
        <div className="sticky-footer-signup-btn-arrow-wrap">
          <img
            src="/images/arrow.png"
            alt="Arrow"
            className="sticky-footer-signup-btn-arrow"
          />
        </div>
        <Link to="/pricing">
          <button className="white-signup-btn">Get CASI Now</button>
        </Link>
      </div>
    </div>
  </>
);

export default StickyFooter;
