import React from 'react';
import { notify } from '../../functions/notify';

export default function SelectContainer({display, title, onBack, onNext, types, setType}) {

    function handleOnNext() {
        if(types) {
            let active = types.filter(i => i.checked === true)
            
            if(active.length === 0) return notify('warning', 'Atleast one field is required')
            
            onNext()
        }
    }


    return (
        <div className='detail-form-main-container' data-active={display} >
            <div className="detail-form-top">
                {
                    title.map((text) => {
                        return (
                            <h1 key={Math.random(20)} className="detail-form-title">{text}</h1>
                        )
                    })
                }
            </div>

            <div className="detail-form-content-container">
                {
                    types.map((data) => {
                        return (
                            <div key={data.key} className="detail-form-box" onClick={() => setType(data.key)}>
                                <div className="detail-form-top">
                                    <input onClick={() => setType(data.key)} onChange={() => setType(data.key)} checked={data.checked} type="checkbox" name="indust-type" className='detail-form-checkbox' />
                                </div>
                                <div className="detail-form-content">
                                    <div className="detail-form-image-wrap">
                                        <img src={data.image} alt={data.alt || 'Type'} className="detail-form-image" />
                                    </div>
                                    <p className="detail-form-text">{data.text}</p>
                                </div>
                            </div>
                        )
                    })
                }
            </div>

            <div className="detail-form-button-wrap">
                {
                    onNext ? <button onClick={handleOnNext} className="detail-form-button">Next</button> : null
                }
                {
                    onBack ? <button onClick={onBack} className="detail-form-button">Back</button> : null
                }
            </div>
        </div>
    );
}
