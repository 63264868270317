import { Grid, Paper, TextField } from "@mui/material";
import React from "react";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import { userLogin } from "../functions/api/login";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { exportUpdateUser } from "../layout/Navbar";

export default function Login() {
    
    const navigator = useNavigate()
    const location = useLocation()
    const state = location.state || {}

    function onSubmit(e) {
        let btn = document.getElementById('login-submit-btn')
        let email = document.getElementById('login-email-txt')
        let pwd = document.getElementById('login-password-txt')
        let remember = document.getElementById('login-remember-checkbox')

        if(btn) btn.dataset.active = "false"

        userLogin(email.value, pwd.value, remember.checked, (status) => {
            if(btn) btn.dataset.active = "true"

            if(status === 'success'){
                exportUpdateUser()
                
                if(state.redirectTo) {
                    navigator(state.redirectTo, {
                        state: {
                            redirectTo: null,
                            ...state,
                        }
                    })
                }else{
                    navigator('/')
                }
            }
        })
    }

    return (
        <div className="grid-container" id="login-page">
        <main className="max-width">
            <Grid className="max-width">
            <Paper elevation={10} className="Paper">
                <h1 className="login-heading">Login</h1>
                <div className="login-inputs-wrap">
                    <TextField
                    size="small"
                    label="Email"
                    placeholder="Email"
                    fullWidth
                    type="email"
                    className="username-txt"
                    id="login-email-txt"
                    />
                    <TextField 
                    size="small"
                    label="Password"
                    placeholder="password"
                    type="password"
                    className="password-txt"
                    fullWidth
                    id="login-password-txt"
                    />
                </div>
                <div className="login-remember-checkbox-wrap">
                    <div className="login-remember-checkbox-form">
                        <FormControlLabel
                        control={<Checkbox id="login-remember-checkbox" name="checkedB" color="primary" />}
                        label="Remember me"
                        />
                    </div>
                </div>
                <div className="button-wrap">
                <button onClick={onSubmit} data-active="true" id="login-submit-btn" className="button">
                    Login
                </button>
                </div>

                <div className="login-link-wrap">
                    <p className="login-link-text">Don't have an account yet ?</p>
                    <Link className="login-link" to="/signup">Sign Up</Link>
                </div>
            </Paper>
            </Grid>
        </main>
        </div>
    );
}
