import React from 'react'
import Footer from '../components/footer/index'
import {FaFacebook, FaYoutube, FaTwitter,FaLinkedin} from 'react-icons/fa';
import { Link } from 'react-router-dom';

export default function FooterContainer () {
     return (
         <Footer>
             <Footer.Wrapper>
                 <Footer.Row>
                 <Footer.Column>
                    <Footer.Title>
                        <Link to="/">
                            <img src="/logo-white.png" alt="Logo" className="footer-logo" />
                        </Link>
                    </Footer.Title>
                    <p className='footer-info-title'>
                        The CASI cloud platform utilizes sensor data captured from the field to optimize any process.
                        Whether you want to digitize your warehouse or data center, or connect to PLCs, HMIS, or any
                        machine sensor…. casi.io makes it happen!
                    </p>
                    <p className='footer-info-founder'>- CEO -</p>
                 </Footer.Column>
                 <Footer.Column>
                     <Footer.Title>Contact</Footer.Title>
                     <p className='footer-info-title'>Address</p>
                     <span className='footer-info'>1400 S Sherman St STE 116 Richardson, TX 75081 USA</span>
                     <p className='footer-info-title'>Telephone</p>
                     <a href="tel:2149005381" className='footer-info'>(214) 900-5381</a>
                     <p className='footer-info-title'>Email</p>
                     <a href="mailto:support@casi.io" className='footer-info'>support@casi.io</a>
                 </Footer.Column>
                 <Footer.Column>
                     <Footer.Title>About</Footer.Title>
                     <Footer.Link href="/features">Features</Footer.Link>
                     <Footer.Link href="/pricing">Pricing</Footer.Link>
                     <Footer.Link href="/shop">Shop</Footer.Link>
                     <Footer.Link href="/contactus">Contact Us</Footer.Link>
                 </Footer.Column>
                 <Footer.Column>
                     <Footer.Title>Social Media</Footer.Title>
                     <Footer.Link style={{display:"flex", gap:"5px", alignItems:"center"}} href='https://www.facebook.com/heycasi' target="_blank"><FaFacebook className="social"/>Facebook</Footer.Link>
                     <Footer.Link style={{display:"flex", gap:"5px", alignItems:"center"}} href='https://www.youtube.com/channel/UCCOxPFEEady1_OdxPrnqGCw' target="_blank"><FaYoutube className="social"/>Youtube</Footer.Link>
                     <Footer.Link style={{display:"flex", gap:"5px", alignItems:"center"}} href='https://www.linkedin.com/company/casi-iot' target="_blank"><FaLinkedin className="social"/>LinkedIn</Footer.Link>
                     <Footer.Link style={{display:"flex", gap:"5px", alignItems:"center"}} href='https://twitter.com/casi_io' target="_blank"><FaTwitter className="social"/>Twitter</Footer.Link>
                 </Footer.Column>
                 </Footer.Row>
             </Footer.Wrapper>
         </Footer>
     )
         
     
 }