import React, { useState, useMemo, useEffect } from 'react'
import { getUser } from '../../../../functions/api/user';
import { notify } from '../../../../functions/notify';
import StripeCard, { exportSubmitCard } from '../../../StripeCard'
import Select from 'react-select'
import countryList from 'react-select-country-list'
import { purchaseHardware } from '../../../../functions/api/billing';
import { applyCoupon } from '../../../../functions/api/coupon';

export default function Details({ shipping, total, hardware, onSuccess }) {
    const [country, setCountry] = useState({ value: 'US', label: 'United States' })
    const options = useMemo(() => countryList().getData(), [])
    const [Total, setTotal] = useState(0)
    const [SubTotal, setSubTotal] = useState(0)

    useEffect(() => {
        setTotal(parseFloat(total))
        setSubTotal(parseFloat(total))
    }, [total])


    function handleOnSubmit() {
        let name = document.getElementById('add_device-name')
        let address = document.getElementById('add_device-address')
        let phone = document.getElementById('add_device-phone')
        let email = document.getElementById('add_device-email')
        let city = document.getElementById('add_device-city')
        let postcode = document.getElementById('add_device-postcode')
        let optional_address = document.getElementById('add_device-optional_address')
        let state_province = document.getElementById('add_device-state_province')
        let paynow = document.getElementById('paynow-btn')
        let coupon = document.getElementById('add_device-coupon')

        if (
            name.value !== ''
            && address.value !== ''
            && email.value !== ''
            && city.value !== ''
            && postcode.value !== ''
            && state_province.value !== ''
            && country.label !== ''
        ) {
            if (paynow) paynow.dataset.active = "false"

            if (!Array.isArray(hardware)) hardware = []

            hardware.push({
                shipping: shipping
            })

            let data = {
                name: name.value,
                address: address.value,
                phone: phone.value,
                email: email.value,
                optional_address: optional_address.value,
                state_province: state_province.value,
                city: city.value,
                postcode: postcode.value,
                country: country.label,
                hardware: hardware,
                price: total,
                quantity: 1,
                coupon: coupon?.value
            }

            exportSubmitCard(data)

        } else {
            notify('warning', 'All fields are required')
        }

    }

    function onStripePayload(payload, data) {
        let paynow = document.getElementById('paynow-btn')
        data['payload'] = payload

        purchaseHardware(data, (status) => {
            if (paynow) paynow.dataset.active = "true"

            if (status === 'success') {
                onSuccess()
            }
        })
    }

    const changeHandler = (value) => {
        setCountry(value)
    }

    useEffect(() => {
        let isMounted = true;

        if (isMounted) {
            let name = document.getElementById('add_device-name')
            let address = document.getElementById('add_device-address')
            let phone = document.getElementById('add_device-phone')
            let email = document.getElementById('add_device-email')
            let city = document.getElementById('add_device-city')
            let postcode = document.getElementById('add_device-postcode')
            let optional_address = document.getElementById('add_device-optional_address')
            let state_province = document.getElementById('add_device-state_province')

            getUser((response) => {
                let data = response.data
                if (data) {
                    if (data.name) name.value = data.name;
                    if (data.address) address.value = data.address;
                    if (data.city) city.value = data.city;
                    if (data.country) {
                        let country_data = options.find(i => i.label.toLowerCase() === data.country.toLowerCase())
                        setCountry(country_data)
                    }
                    if (data.postcode) postcode.value = data.postcode;
                    if (data.phone) phone.value = data.phone;
                    if (data.email) email.value = data.email;
                    if (data.optional_address) optional_address.value = data.optional_address;
                    if (data.state_province) state_province.value = data.state_province;
                }
            })
        }

        return () => {
            isMounted = false;
        }
    }, [options])

    const CountrySelectStyle = ({
        control: base => ({
            ...base,
            border: '2px solid rgba(0, 0, 0, 0.2)',
            boxShadow: "none"
        })
    })

    const CountrySelectTheme = (theme) => {
        return ({
            ...theme,
            colors: {
                ...theme.colors,
                primary: '#423F3E',
            },
        })
    }

    function handleOnCouponApply() {
        let coupon = document.getElementById('add_device-coupon')

        if (!coupon?.value) return notify('warning', 'Invalid coupon')

        applyCoupon(coupon.value, (data) => {
            if (!data?.id) return notify('warning', 'Invalid coupon')
            let amount = SubTotal

            if (data.offType === 'percentOff') {
                amount = amount - ((amount * parseFloat(data.percentOff || 0)) / 100)
            } else {
                amount = amount - parseFloat(data.amountOff || 0)
            }
            
            amount = round(amount)
            setTotal(amount)
        })
    }

    function round(num) {
        var m = Number((Math.abs(num) * 100).toPrecision(15));
        return Math.round(m) / 100 * Math.sign(num);
    }

    return (
        <div className="details">

            <div className="inputs">
                <div className="input_wrap">
                    <p className="title">Full Name</p>
                    <input id="add_device-name" type="text" className="input" />
                </div>
                <div className="double_wrap">
                    <div className="input_wrap">
                        <p className="title">Email</p>
                        <input id="add_device-email" type="email" className="input" disabled />
                    </div>
                    <div className="input_wrap">
                        <p className="title">Phone Number <span className="optional">(optional)</span></p>
                        <input id="add_device-phone" type="text" className="input" />
                    </div>
                </div>
                <div className="input_wrap">
                    <p className="title">Country</p>
                    <Select
                        maxMenuHeight={160}
                        styles={CountrySelectStyle}
                        theme={CountrySelectTheme}
                        options={options}
                        value={country}
                        onChange={changeHandler} />
                </div>
                <div className="input_wrap">
                    <p className="title">Street Address</p>
                    <input id="add_device-address" type="text" className="input" />
                </div>
                <div className="input_wrap">
                    <p className="title">Street Address 2 <span className="optional">(optional)</span></p>
                    <input id="add_device-optional_address" type="text" className="input" />
                </div>
                <div className="input_wrap">
                    <p className="title">State / Province</p>
                    <input id="add_device-state_province" type="text" className="input" />
                </div>
                <div className="double_wrap">
                    <div className="input_wrap">
                        <p className="title">City</p>
                        <input id="add_device-city" type="text" className="input" />
                    </div>
                    <div className="input_wrap">
                        <p className="title">Post Code</p>
                        <input id="add_device-postcode" type="text" className="input" />
                    </div>
                </div>
                <div className="input_wrap coupon-input">
                    <p className="title">Coupon Code</p>

                    <div className="wrap">
                        <input id="add_device-coupon" type="text" className="input" />

                        <button onClick={handleOnCouponApply} className="button">Apply</button>
                    </div>
                </div>

                <StripeCard callback={onStripePayload} hidebtn={true} hidetitle={true} defaultPayment={true} />
            </div>

            <div className="total-wrap">
                <div className="total-section">
                    <p className="title">Sub Total</p>
                    <p className="price">$ {SubTotal}</p>
                </div>
                <div className="total-section">
                    <p className="title">Total</p>
                    <p className="price">$ {Total}</p>
                </div>
            </div>

            <div className="button_wrap">
                <button id="paynow-btn" className="button" onClick={() => handleOnSubmit()} >Pay now</button>
            </div>
        </div>
    )
}
